<template>
  <h3 class="text-h3">
    {{ step.label }}
  </h3>
  <i v-if="step.initial">
    {{ $t(`workflows.steps.${step.data.action_type}_initial`) }}
  </i>
  <i v-else-if="step.final">
    {{ $t(`workflows.steps.${step.data.action_type}_final`) }}
  </i>
  <i v-else>
    {{ $t(`workflows.steps.${step.data.action_type}`) }}
  </i>

  <div
    v-if="!stepConfigSchema"
    class="label top-gap"
  >
    {{ $t('workflows.errors.step_deprecated', { step: step.label }) }}
  </div>
  <div
    v-else
    class="label top-gap"
  >
    <div class="label top-gap">
      {{ $t('forms.name') }}
    </div>
    <v-text-field
      v-model="stepConfig.name"
      style="margin-top: 5px; margin-bottom: -25px"
      variant="outlined"
      color="primary"
      density="compact"
      :disabled="!isWorkflowDraft"
      @blur="emitConfigChangedEvent()"
      @keydown.enter="emitConfigChangedEvent()"
    />

    <div v-if="stepConfig.action_type == 'state'">
      <div class="label top-gap">
        {{ $t('workflows.steps.state_value') }}
      </div>

      <v-text-field
        v-model="stepConfig.action"
        style="margin-top: 5px; margin-bottom: -25px"
        variant="outlined"
        color="primary"
        density="compact"
        :disabled="!isWorkflowDraft"
        @blur="emitConfigChangedEvent()"
        @keydown.enter="emitConfigChangedEvent()"
      />

      <div
        v-if="['started', 'done'].includes(stepConfig.action)"
      >
        <div
          class="radio-box top-gap"
          style="width: fit-content"
        >
          <v-checkbox
            v-model="stepConfig.final"
            style="margin-top: -9px"
            color="primary"
            :label="$t(`workflows.steps.state_final`)"
            :disabled="!isWorkflowDraft"
            @blur="emitConfigChangedEvent()"
          />
        </div>
      </div>
    </div>

    <PythonEditor
      v-if="step.data.action_type === 'python'"
      ref="stepPythonEditor"
      class="mt-4"
      :code="step.data.action"
      :disabled="!isWorkflowDraft"
      :title="step.data.name || $t('workflows.steps.' + step.data.action_type)"
      @save-config="emitConfigChangedEvent()"
      @update:code="code => step.data.action = code"
    />

    <div v-if="stepConfigSchema && stepConfig.config">
      <div
        v-for="(property, propName) in basicProperties"
        :key="propName"
      >
        <div
          v-if="property.type === 'string'"
          class="top-gap"
        >
          {{ $t(`workflows.steps.config.${propName}`) }}
          <v-text-field
            v-if="!property.agent_type"
            v-model="stepConfig.config[propName]"
            style="margin-top: 5px; margin-bottom: -25px"
            variant="outlined"
            color="primary"
            density="compact"
            :disabled="!isWorkflowDraft"
            @blur="emitConfigChangedEvent()"
            @keydown.enter="emitConfigChangedEvent()"
          />
          <CustomSelect
            v-else-if="property.agent_type === 'email_classifier'"
            :items="emailsClassificationModels"
            :selected="selectedEmailClassificationModel"
            :disabled="!isWorkflowDraft"
            @selected-changed="handleEmailClassificationModelSelect"
            @get-more="(filter, reset) => fetchEmailsClassificationModels(reset)"
          />
          <DocTypeSelect
            v-else-if="property.agent_type === 'extraction_agent'"
            :disabled="!isWorkflowDraft"
            :initial="stepConfig.config[propName]"
            :select-first="false"
            @selected-changed="handleDocTypeSelect"
            filter-with-data-points
            filter-with-groups
          />
          <WorkflowSelect
            v-else-if="property.agent_type === 'workflow'"
            return-type="uuid"
            :disabled="!isWorkflowDraft"
            :workflows="workflows"
            :selected="stepConfig.config[propName]"
            @selected-changed="handleWorkflowSelect"
            @get-more="(filter, reset) => getMoreWorkflows(filter, reset)"
          />
        </div>
        <div
          v-else-if="property.type === 'boolean'"
          class="radio-box top-gap"
          style="width: fit-content"
        >
          <v-checkbox
            v-model="stepConfig.config[propName]"
            style="margin-top: -9px"
            color="primary"
            :label="$te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title"
            :disabled="!isWorkflowDraft"
            @change="emitConfigChangedEvent()"
          />
        </div>
        <div
          v-else-if="property.type === 'object' && property.agent_type === 'documents_classification_agent'"
          class="top-gap"
        >
          {{ $t(`workflows.steps.config.${propName}`) }}
          <CustomSelect
            name-field="title"
            value-field="value"
            :items="documentsClassificationAgentsItems"
            :selected="selectedDocumentClassificationAgentId"
            :disabled="!isWorkflowDraft"
            @selected-changed="handleDocumentClassificationAgentSelect"
            @get-more="fetchDocumentsClassificationAgents"
          />
        </div>
      </div>
      <div
        v-if="advancedProperties"
        class="clickable top-gap"
        @click="showAdvanced = !showAdvanced"
      >
        <h1 class="text-h4 text-color--primary inline-middle">
          {{ $t('workflows.steps.config.show_advanced') }}
        </h1>
        <v-icon color="primary">
          {{ showAdvanced ? 'fas fa-angle-down' : 'fas fa-angle-right' }}
        </v-icon>
      </div>
      <div v-if="showAdvanced">
        <div
          v-for="(property, propName) in advancedProperties"
          :key="propName"
        >
          <div
            v-if="property.type === 'string'"
            class="top-gap-sm"
          >
            {{ $te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title }}
            <v-text-field
              v-model="stepConfig.config[propName]"
              style="margin-top: 5px; margin-bottom: -25px"
              variant="outlined"
              color="primary"
              density="compact"
              :disabled="!isWorkflowDraft"
              @blur="emitConfigChangedEvent()"
              @keydown.enter="emitConfigChangedEvent()"
            />
          </div>
          <div
            v-else-if="['integer', 'number'].includes(property.type)"
            class="top-gap-sm"
          >
            {{ $te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title }}
            <v-text-field
              v-model.number="stepConfig.config[propName]"
              style="margin-top: 5px; margin-bottom: -25px"
              variant="outlined"
              color="primary"
              density="compact"
              type="number"
              :disabled="!isWorkflowDraft"
              @blur="parseNumberAndEmitConfigChangedEvent(propName)"
              @keydown.enter="parseNumberAndEmitConfigChangedEvent(propName)"
            />
          </div>
          <div
            v-else-if="property.type === 'array'"
            class="top-gap-sm"
          >
            {{ $te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title }}
            <div
              class="top-gap-sm"
              style="margin-bottom: -10px"
            >
              <MaxWidthChip
                v-for="(item, itemIndex) in stepConfig.config[propName]"
                :key="item"
                :text-array="[item]"
                :disabled="!isWorkflowDraft"
                @close-click="deleteItem(propName, itemIndex)"
                closeable
              />
              <div>
                <v-text-field
                  v-model="propertyHelper[propName]"
                  class="inline-middle right-gap"
                  style="width: 250px"
                  variant="outlined"
                  color="primary"
                  density="compact"
                  :placeholder="$t('dataPoints.type_new_text')"
                  :disabled="!isWorkflowDraft"
                  @keydown.enter="addItem(propName)"
                />
                <v-btn
                  class="inline-middle add-button"
                  color="primary"
                  :disabled="disableAddItem(propName)"
                  @click="addItem(propName)"
                  rounded
                >
                  <v-icon
                    size="16"
                    start
                  >
                    fas fa-plus
                  </v-icon>
                  {{ $t('docTypes.add') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 26px">
          <div
            v-for="(property, propName) in advancedProperties"
            :key="propName"
          >
            <div
              v-if="property.type === 'boolean'"
              class="radio-box top-gap"
              style="width: fit-content"
            >
              <v-checkbox
                v-model="stepConfig.config[propName]"
                style="margin-top: -9px"
                color="primary"
                :label="$te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title"
                :disabled="!isWorkflowDraft"
                @change="emitConfigChangedEvent()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect.vue';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';
import PythonEditor from '@/components/extract/elements/Workflows/PythonEditor.vue';
import WorkflowSelect from "@/components/extract/elements/Workflows/WorkflowSelect.vue";
import MaxWidthChip from '@/components/common/elements/General/MaxWidthChip.vue';

import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';


export default {
  name: 'StepConfiguration',

  components: {
    DocTypeSelect,
    CustomSelect,
    PythonEditor,
    WorkflowSelect,
    MaxWidthChip,
  },

  data() {
    return {
      showAdvanced: false,
      classifyMailModels: [],
      documentClassificationAgents: [],
      documentClassificationAgentsOffset: 0,
      totalDocumentClassificationAgents: 0,
      documentClassificationAgentsLimit: 20,
      emailsClassificationModels: [],
      emailsClassificationModelsOffset: 0,
      totalEmailsClassificationModels: 0,
      emailsClassificationModelsLimit: 20,
      propertyHelper: {},
    };
  },

  computed: {
    basicProperties() {
      if (!this.stepConfigSchema) {
        return null;
      }
      return Object.keys(this.stepConfigSchema.properties)
        .filter(p => !this.stepConfigSchema.properties[p].advanced)
        .reduce((obj, key) => {
          obj[key] = this.stepConfigSchema.properties[key];
          return obj;
        }, {});
    },

    advancedProperties() {
      if (!this.stepConfigSchema) {
        return null;
      }
      return Object.keys(this.stepConfigSchema.properties)
        .filter(p => this.stepConfigSchema.properties[p].advanced)
        .reduce((obj, key) => {
          obj[key] = this.stepConfigSchema.properties[key];
          return obj;
        }, {});
    },

    selectedEmailClassificationModel() {
      const model = this.emailsClassificationModels.find(m => m.name === this.stepConfig.config.model);
      return model ? model.id : 0;
    },

    documentsClassificationAgentsItems() {
      return this.documentClassificationAgents.map((agent) => ({
        title: agent.name,
        value: agent.id,
      }));
    },

    selectedDocumentClassificationAgentId() {
      return this.stepConfig?.config?.classification_agent?.id ?? 0;
    },

  },

  watch: {
    stepConfig: {
      handler() {
        if (this.step.data.action_type === 'python') {
          this.$nextTick(() => {
            this.$refs.stepPythonEditor.highlightCode();
          });
        }
      },
    },
  },

  created() {
    this.fetchEmailsClassificationModels();
    this.fetchDocumentsClassificationAgents();
  },

  methods: {
    handleWorkflowSelect(uuid) {
      this.stepConfig.config['workflow'] = uuid;
      this.emitConfigChangedEvent();
    },

    handleEmailClassificationModelSelect(id) {
      const model = this.emailsClassificationModels.find((classificationModel) => classificationModel.id === id);
      if (model) {
        this.stepConfig.config['model'] = model.name;
        this.emitConfigChangedEvent();
      }
    },

    handleDocTypeSelect(id) {
      if (this.stepConfig.config['doctype'] !== id) {
        this.stepConfig.config['doctype'] = id;
        this.emitConfigChangedEvent();
      }
    },

    handleDocumentClassificationAgentSelect(selectedDocumentClassificationAgentId) {
      const selectedDocumentClassificationAgent = this.documentClassificationAgents.find(agent => agent.id === selectedDocumentClassificationAgentId);
      if (!selectedDocumentClassificationAgent) {
        return;
      }
      this.stepConfig.config.classification_agent = {
        id: selectedDocumentClassificationAgent.id,
        name: selectedDocumentClassificationAgent.name,
      };
      this.emitConfigChangedEvent();
    },

    async fetchDocumentsClassificationAgents(filter = '', reset = true) {
      if (this.documentClassificationAgentsOffset > this.totalDocumentClassificationAgents) {
        return;
      }

      if (reset) {
        this.documentClassificationAgentsOffset = 0;
        this.documentClassificationAgents = [];
      } else {
        this.documentClassificationAgentsOffset += this.documentClassificationAgentsLimit;
      }

      const response = await ClassificationAgentsAPI.fetchAll({
        name: filter,
        classificationModelType: 'document_models',
        offset: this.documentClassificationAgentsOffset,
        limit: this.documentClassificationAgentsLimit,
      });
      this.documentClassificationAgents = this.documentClassificationAgents.concat(...response.data);
      this.totalDocumentClassificationAgents = parseInt(response.headers['x-total-count'], 10);
    },

    async fetchEmailsClassificationModels(reset = true) {
      if (this.emailsClassificationModelsOffset > this.totalEmailsClassificationModels) {
        return;
      }

      if (reset) {
        this.emailsClassificationModelsOffset = 0;
        this.emailsClassificationModels = [];
      } else {
        this.emailsClassificationModelsOffset += this.emailsClassificationModelsLimit;
      }

      const response = await ClassifyModelAPI.getClassificationModels({
        classificationModelsType: 'email_models',
        offset: this.emailsClassificationModelsOffset,
        limit: this.emailsClassificationModelsLimit,
      });
      this.emailsClassificationModels = this.emailsClassificationModels.concat(...response.data);
      this.totalEmailsClassificationModels = parseInt(response.headers['x-total-count'], 10);
    },

    async getMoreWorkflows(name) {
      const [workflows,] = await WorkflowAPI.getByUUID(null, name);
      this.workflows = workflows;
    },

    parseNumberAndEmitConfigChangedEvent(propName) {
      this.stepConfig.config[propName] = parseFloat(this.stepConfig.config[propName]) || null;
      this.$emit('config-changed');
    },

    emitConfigChangedEvent() {
      this.$emit('config-changed');
    },

    addItem(propName) {
      const newItemName = this.propertyHelper[propName].trim();
      if (!this.stepConfig.config[propName].includes(newItemName)) {
        this.stepConfig.config[propName].push(newItemName);
        this.emitConfigChangedEvent();
      }
      this.propertyHelper[propName] = '';
    },


    deleteItem(propName, i) {
      this.stepConfig.config[propName].splice(i, 1);
      this.emitConfigChangedEvent();
    },

    disableAddItem(propName) {
      if (
        !this.propertyHelper[propName]
        || this.propertyHelper[propName].trim() === ''
        || this.stepConfig.config[propName].includes(this.propertyHelper[propName].trim())
      ) {
        return true;
      }
      return false;
    },
  },

  props: {
    isWorkflowDraft: {
      type: Boolean,
      required: true
    },

    step: {
      type: Object,
      required: true
    },

    stepConfig: {
      type: Object,
      required: true
    },

    stepConfigSchema: {
      type: [Object, null],
      required: true
    },
  },

  emits: ['config-changed'],
}

</script>

<style scoped>

.v-text-field, .model-select, .doctype-select,
.v-input.v-input--horizontal.v-input--center-affix.v-input--density-compact.v-locale--is-ltr.v-input--dirty.v-checkbox {
  box-sizing: border-box !important;
}

.add-button {
  position: relative;
  top: -10px;
}

</style>
