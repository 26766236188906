<template>
  <div class="radio-box inline-middle bottom-gap-sm right-gap-sm">
    <v-checkbox
      v-model="isChecked"
      style="margin-top: -9px"
      color="primary"
      :label="label"
      @update:model-value="v => $emit('update:model-value', v)"
    />
  </div>
</template>

<script>
export default {
  name: 'BoxedCheckbox',

  data() {
    return {
      isChecked: this.value,
    }
  },

  watch: {
    value(newVal) {
      this.isChecked = newVal;
    }
  },

  props: {
    label: {
      type: String,
      required: true
    },

    value: {
      type: Boolean,
      default: false
    },
  },

  emits: ['update:model-value'],
}
</script>
