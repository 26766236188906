import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/documents/';

export class ClassifyDocumentsAPI {
  static get(offset = 0, limit = 20, sortDesc = true, name_filter = '', classifiedWithClassificationAgent = false) {
    return http.get(BASE_PATH, {
        params: {
          sort_desc: sortDesc,
          name_filter,
          hitl_required: true,
          auto_created: true,
          classified_with_classification_agent: classifiedWithClassificationAgent,
          offset: offset,
          limit: limit,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getDocument(id) {
    return http.get(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static delete(id) {
    return http.delete(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getPages(id, onlyNotDiscarded=true) {
    return http.get(`${BASE_PATH}${id}/pages`, {
        params: {
          only_not_discarded: onlyNotDiscarded,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getPageImage(document_id, page_id) {
    return http.get(`${BASE_PATH}image/${document_id}/pages/${page_id}`, {
        params: {
          thumbnail: false,
        },
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getPageThumbnail(document_id, page_id) {
    return http.get(`${BASE_PATH}image/${document_id}/pages/${page_id}`, {
        params: {
          thumbnail: true,
        },
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static updateDocument(document_id, data) {
    return http.patch(`${BASE_PATH}${document_id}/`, data, {
        globalErrorHandlingOptOut: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static copyToDataset(document_id, dataset_id, entry_name = null, pages = null) {
    const payload = {};
    if (entry_name) payload.entry_name = entry_name;
    if (pages) payload.pages = pages;

    return http.post(`${BASE_PATH}${document_id}/copy/${dataset_id}`, payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static validateDocument(document_id, reviewerComment) {
    return http.post(`${BASE_PATH}${document_id}/validate`, { reviewer_comment: reviewerComment })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
