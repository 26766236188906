<template>
  <div class="training-models-table">
    <TableWithFooter
      no-results-message="models.no_training"
      :loading="loading"
      :paginated-items-length="trainingClassifiers.length"
      :total="totalTrainingClassifiers"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col>
          {{ $t('models.classifiers_in_training') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="classifier in trainingClassifiers"
            :key="classifier.id"
            class="table-row fade-in table-row-height"
          >
            <v-col>
              <div class="ellipsis">
                <v-icon
                  class="mr-1"
                  color="primary"
                  size="16"
                >
                  fas fa-spinner fa-pulse
                </v-icon>
                {{ classifier.name }}
              </div>
            </v-col>
            <v-col cols="3">
              <v-btn
                color="primary"
                class="small-button"
                style="margin-top: -6px"
                @click="handleDeleteButton(classifier.name)"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-times
                </v-icon>
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title_training')"
      :message="$t('models.confirm_delete_training')"
      @confirm="deleteModel"
    />
  </div>
</template>

<script>

import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import model_mixin from '@/mixins/model.js';
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

export default {
  name: 'TrainingClassifiersTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableWithFooter,
    ConfirmDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      deleteDialog: false,
      toDelete: '',
      loading: false,
      statusCheck: null,
      itemsPerPage,
      currentPage,
      trainingClassifiers: [],
    };
  },

  computed: {
    totalTrainingClassifiers() {
      return this.$store.getters.totalTrainingClassifiers;
    },
  },

  watch: {
    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.toDelete = '';
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.currentPage = 1;
      this.modelsCountCheck();
    },

    currentPage() {
      this.modelsCountCheck();
    },
  },

  mounted() {
    this.modelsCountCheck();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    refreshModels() {
      // Changing this setup might result in models not loading after training
      this.loading = true;
      setTimeout(() => {
        this.modelsCountCheck();
      }, 1000);
      this.modelsCountCheck();
    },

    modelsCountCheck() {
      this.getClassifiers(true);
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.totalTrainingClassifiers === 0) {
          clearInterval(this.statusCheck);
        } else {
          this.getClassifiers(false);
        }
      }, 10000);
    },

    async getClassifiers(loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const [trainedClassifiersResponse, trainingClassifiersResponse] = await Promise.all([
          /* For trained classifiers we are only interested in the total,
           * that's why we set the limit to 1. */
          ClassifyModelAPI.getClassificationModels({
            limit: 1,
            hasTrained: true,
          }),
          ClassifyModelAPI.getClassificationModels({
            offset,
            limit: this.itemsPerPage,
            descendingSort: this.sortDesc,
            hasTrained: false,
          }),
        ]);
        const totalTrainedClassifiers = parseInt(trainedClassifiersResponse.headers['x-total-count'], 10);
        const totalTrainingClassifiers = parseInt(trainingClassifiersResponse.headers['x-total-count'], 10);

        this.trainingClassifiers = trainingClassifiersResponse.data;

        this.$store.commit('setTotalClassifiers', totalTrainedClassifiers);
        this.$store.commit('setTotalTrainingClassifiers', totalTrainingClassifiers);
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async deleteModel() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await ClassifyModelAPI.deleteModel({modelName: this.toDelete});
        this.finishDeletion();
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async finishDeletion() {
      this.deleteDialog = false;
      await this.getClassifiers();
      this.currentPage = 1;
      this.toDelete = '';
      this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton(name) {
      this.deleteDialog = true;
      this.toDelete = name;
    },
  }
}
</script>
