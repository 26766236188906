<template>
  <v-card class="new-subgroup-card pa-3 my-4 mx-3">
    <div
      class="d-flex justify-space-between pb-3"
      style="border-bottom: solid 1px #c8c8c8"
    >
      <div
        class="d-flex text-uppercase text-caption grey--text"
        style="max-width: calc(50%)"
      >
        <div class="ellipsis d-inline-block">
          {{ group.group_name }}
        </div>
        <div>
          [{{ index + 1 }}]
        </div>
      </div>
      <div class="flex-shrink-0">
        <v-btn
          class="mr-2 small-button"
          color="black"
          style="box-shadow: none"
          variant="outlined"
          @click="$emit('cancelAddSubgroup')"
          rounded
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="small-button"
          @click="$emit('saveSubgroup', group)"
          rounded
        >
          {{ $t("save") }}
        </v-btn>
      </div>
    </div>
    <div class="d-flex align-center my-2">
      <div class="mr-3">
        {{ $t("models.add_label") }}:
      </div>
      <v-select
        class="mt-0 inline-middle"
        style="width: 300px;"
        variant="outlined"
        color="primary"
        density="compact"
        item-title="name"
        item-value="value"
        :items="availableLabels"
        :disabled="availableLabels.length === 0"
        :placeholder="$t('dataPoints.group_labels')"
        @update:model-value="addLabel"
      />
    </div>
    <div
      v-if="values.length === 0"
      class="text-center"
      style="color: #b9b9b9"
    >
      {{ $t("dataPoints.no_values") }}
    </div>
    <div
      v-for="(value, i) in values"
      :key="i"
      :ref="`new-value-${i}`"
      class="label-container d-flex align-center noselect"
      @mouseover="selectLabel(value.label)"
      @mouseleave="selectedLabel = ''"
    >
      <div class="label-name-container mr-3">
        {{ getValueLabel(value) }}
      </div>
      <v-text-field
        v-if="editingLabel === value.label"
        :ref="`valueField-${value.label}`"
        v-model="value.valid_value"
        style="position: relative; top: 3px"
        variant="outlined"
        color="primary"
        density="compact"
        @keydown.esc="$emit('editSubgroupLabel', '')"
        @keydown.enter="saveValue"
      />
      <div
        v-else
        class="value-container pa-1"
      >
        {{ value.valid_value || value.value || "N/A" }}
      </div>
      <v-icon
        v-if="selectedLabel === value.label"
        class="clickable close-icon"
        size="12"
        @click="deleting = value.id; deleteDialog = true;"
        @mouseover="selectLabel(value.label)"
      >
        fas fa-times
      </v-icon>
      <v-tooltip bottom>
        <template #activator="{ props }">
          <div
            class="clickable edit-icon ml-3"
            v-bind="props"
          >
            <v-icon
              v-if="editingLabel === value.label"
              style="margin-top: 6px"
              color="primary"
              size="16"
              @click="$emit('editSubgroupLabel', '')"
            >
              fas fa-check
            </v-icon>
            <v-icon
              v-else
              class="edit-icon-icon"
              color="primary"
              size="16"
              @click="handleClickEdit(value.label)"
            >
              fas fa-pen
            </v-icon>
          </div>
        </template>
        {{
          editingLabel === value.label
            ? $t("dataPoints.finish_sgroup_value_editing")
            : $t("dataPoints.start_sgroup_value_editing")
        }}
      </v-tooltip>
    </div>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('models.delete_label')"
      :message="$t('dataPoints.label_delete_confirmation')"
      @confirm="deleteLabel"
    />
  </v-card>
</template>
<script>
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";

import format_mixin from "@/mixins/format";
import ui_mixin from "@/mixins/ui";

export default {
  name: "NewSubgroup",

  mixins: [
    format_mixin,
    ui_mixin,
  ],

  components: {
    ConfirmDialog,
  },

  data() {
    return {
      deleting: -1,
      selectedLabel: "",
      deleteDialog: false,
    };
  },

  computed: {
    takenLabels() {
      return this.values.map((item) => item.label);
    },
  },

  methods: {
    addLabel(label) {
      this.$emit("addSubgroupLabel", this.group, label);
    },

    selectLabel(label) {
      this.selectedLabel = label;
    },

    deleteLabel() {
      this.$emit("deleteSubgroupLabel", this.deleting);
      this.deleting = -1;
      this.deleteDialog = false;
    },

    handleClickEdit(label) {
      this.$emit("editSubgroupLabel", label);
      setTimeout(() => {
        this.$refs[`valueField-${label}`][0].focus();
      }, 200);
    },

    saveValue() {
      this.$emit("editSubgroupLabel", "");
    },
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
    groupIndex: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    labelMap: {
      type: Object,
      required: true,
    },
    editingLabel: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'cancelAddSubgroup',
    'saveSubgroup',
    'editSubgroupLabel',
    'addSubgroupLabel',
    'deleteSubgroupLabel',
  ],
};
</script>
<style lang="scss" scoped>
.new-subgroup-card {
  border: solid 1px #ccc2ff;
  background-color: #f4f5f9;
}

.label-container {
  font-size: 0.7rem;
  background-color: white;
  width: 100%;
  padding: 10px;
  position: relative;
  min-height: 65px;
  color: #4c4c4c;
  overflow: hidden;
  border-bottom: #c8c8c8 1px solid;

  &:last-child {
    border-bottom: #fff 0px solid;
  }

  .icon-bg {
    background-color: white;
    width: 55px;
  }

  .value-container {
    width: 80%;
    font-size: 1rem;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.label-name-container {
  width: 200px;
  text-transform: uppercase;
}

.edit-icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
  border: rgb(var(--v-theme-primary)) 1px solid;
  text-align: center;

  .edit-icon-icon {
    margin-top: 6px;
  }
}
</style>
