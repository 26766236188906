<template>
  <div class="configure-labels">
    <v-tabs
      v-model="labelType"
      color="primary"
    >
      <v-tab
        v-for="(i, key) in labelTypes"
        :key="i"
      >
        {{ $t(`models.label_types.${key}`) }}
      </v-tab>
    </v-tabs>
    <div
      class="top-gap-sm"
      style="max-height: 500px; overflow-y: auto; overflow-x: hidden;"
    >
      <v-window
        v-model="labelType"
        color="primary"
      >
        <v-window-item
          v-for="i in labelTypes"
          :key="i"
          style="background-color: #f4f5f9 !important;"
        >
          <div v-if="labelType === labelTypes.annotation">
            <draggable
              v-model="labels"
              ghost-class="ghost-label"
              @start="drag = true"
              @end="drag = false"
              @change="handleDrag"
            >
              <v-row
                v-for="(label, index) in labels"
                :key="index"
                class="label-container bottom-gap-sm mt-0"
                @mouseover="hoveredLabel = label.id"
              >
                <v-icon
                  style="position: absolute; right: 7px; top: 7px;"
                  color="black"
                  size="14"
                  @click="deleteLabelOn = true; deletingLabel = label.id;"
                >
                  fas fa-times
                </v-icon>
                <v-col
                  v-if="editedLabel !== label.id"
                  cols="5"
                  style="padding-top: 5px"
                >
                  <v-icon
                    class="right-gap"
                    style="opacity: 0.5"
                    color="grey"
                    size="17"
                  >
                    fas fa-grip-vertical
                  </v-icon>
                  <div
                    class="ellipsis inline-middle"
                    style="max-width: 170px"
                  >
                    {{ label.name }}
                  </div>
                  <v-tooltip
                    v-if="hoveredLabel === label.id"
                    bottom
                  >
                    <template #activator="{ props }">
                      <v-icon
                        class="clickable left-gap-sm inline-middle"
                        size="12"
                        v-bind="props"
                        @mouseover="hoveredLabel = label.id"
                        @click="startNameEdit(label.id, label.name)"
                      >
                        fas fa-pen
                      </v-icon>
                    </template>
                    {{ $t("models.edit_name") }}
                  </v-tooltip>
                </v-col>
                <v-col
                  v-else
                  cols="5"
                >
                  <div style="position: relative; width: fit-content; top: -6px;">
                    <input
                      ref="labelNameField"
                      v-model="newNameForLabel"
                      class="label-name-field"
                      style="width: 90%;"
                      type="text"
                      @keydown.enter="saveLabelName"
                      @keydown.esc="editedLabel = -1"
                    >
                    <v-icon
                      class="clickable edit-name-icon"
                      size="12"
                      @click="saveLabelName"
                    >
                      fas fa-check
                    </v-icon>
                  </div>
                </v-col>
                <v-col
                  cols="7"
                  class="ellipsis d-flex justify-end"
                >
                  <div
                    style="padding-right: 1px; padding-bottom: 7px; margin-top: -6px; padding-top: 8px; width: fit-content;"
                  >
                    <div
                      class="inline-middle"
                      style="margin-top: -49px"
                    >
                      {{ $t("models.allow_line_breaks") }}
                    </div>
                    <v-switch
                      v-model="label.line_breaks"
                      class="inline-middle left-gap-sm"
                      style="margin-top: -29px"
                      color="primary"
                      @update:model-value="saveLineBreaks(label)"
                      inset
                    />
                    <div
                      class="inline-middle"
                      style="margin-top: -49px; margin-left: 30px"
                    >
                      {{ $t("models.column_label") }}
                    </div>
                    <v-switch
                      v-model="label.column_label"
                      class="inline-middle left-gap-sm"
                      style="margin-top: -29px"
                      color="primary"
                      @change="saveColumnLabel(label)"
                      inset
                    />
                  </div>
                </v-col>
              </v-row>
            </draggable>
          </div>
          <LocationLabelsConfig
            v-if="labelType === labelTypes.location"
            ref="locationLabels"
            :dataset-id="datasetId"
            @delete-location-label="deleteLocationLabel"
            @start-name-edit="(name, id) => startNameEdit(name, id)"
          />
          <DocumentLabelsConfig
            v-else-if="labelType === labelTypes.document"
            ref="documentLabels"
            :dataset-id="datasetId"
          />
        </v-window-item>
      </v-window>
    </div>
    <div
      v-if="labelType === labelTypes.annotation"
      class="d-flex"
      style="margin-top: 0px"
    >
      <v-text-field
        v-model="newLabel"
        class="inline-middle right-gap label-field"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('models.type_label_name')"
        @keydown.enter="addLabel"
      />
      <v-btn
        class="add-button"
        style="height: 37px"
        color="primary"
        :disabled="trimmedLabel === ''"
        @click="addLabel"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t("docTypes.add") }}
      </v-btn>
    </div>
    <AddDocumentLabel
      v-if="labelType === labelTypes.document"
      :dataset-id="datasetId"
      @get-labels="getDocLabels"
    />
    <ConfirmDialog
      v-model="deleteLabelOn"
      :title="$t('models.delete_label')"
      :message="$t('models.label_delete_confirmation')"
      @confirm="deleteLabel"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { VueDraggableNext } from "vue-draggable-next";
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";
import AddDocumentLabel from '@/components/extract/elements/Datasets/AddDocumentLabel';
import DocumentLabelsConfig from '@/components/extract/elements/Datasets/DocumentLabelsConfig';
import LocationLabelsConfig from '@/components/extract/elements/Datasets/LocationLabelsConfig';
import annotation_mixin from '@/mixins/annotation';

export default {
  name: 'ConfigureLabels',

  mixins: [annotation_mixin],

  components: {
    draggable: VueDraggableNext,
    ConfirmDialog,
    AddDocumentLabel,
    DocumentLabelsConfig,
    LocationLabelsConfig,
  },

  data() {
    return ({
      labelType: 'annotation',
      editOldName: '',
      newNameForLabel: '',
      editedLabel: -1,
      hoveredLabel: -1,
      drag: false,
      newLabel: '',
      labels: [],
      docLabels: [],
      deleteLabelOn: false,
      deleteLocation: false,
      deletingLabel: -1,
    })
  },

  computed: {
    labelTypes() {
      const types = {
        annotation: 0,
      };
      if (this.showAll) {
        types.location = 1;
        types.document = 2;
      }
      return types;
    },

    trimmedLabel() {
      return this.newLabel.trim();
    },

    trimmedNewNameForLabel() {
      return this.newNameForLabel.trim();
    },

    files: {
      get() {
        return this.$store.getters.entries[this.datasetId] || [];
      },
      set(files) {
        this.$store.commit(
          'setEntries',
          {
            modelId: this.datasetId,
            entries: files,
          }
        );
      }
    },
  },

  watch: {
    labels: {
      handler(labels) {
        this.$emit('updateLabels', labels);
      },
      deep: true
    },

    labelType(type) {
      if (type === this.labelTypes.document) {
        setTimeout(() => {
          this.getDocLabels();
        }, 10);
      }
    },
  },

  mounted() {
    this.getLabels();
  },

  methods: {
    getDocLabels() {
      const docLabelsComponent = this.$refs.documentLabels;
      docLabelsComponent[docLabelsComponent.length - 1].getDocLabels();
    },

    async saveLabelName() {
      if (this.trimmedNewNameForLabel !== '') {
        try {
          const oldName = this.editOldName;
          const newName = this.trimmedNewNameForLabel.toUpperCase();
          await http.put(
            `dataset/label/${this.editedLabel}/`,
            { name: newName },
          );
          this.editedLabel = -1;
          this.newNameForLabel = '';
          this.files.forEach((file) => {
            if (!file.annotations) {
              file.annotations = {};
            }
            if (file.annotations[oldName]) {
              file.annotations[newName] = file.annotations[oldName];
              if (newName !== oldName) {
                delete file.annotations[oldName];
              }
            }
          });
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getLabels();
      }
    },

    startNameEdit(id, name) {
      this.editedLabel = id;
      this.editOldName = name;
      this.newNameForLabel = name;
      setTimeout(() => {
        this.$refs.labelNameField[0].focus();
      }, 100);
    },

    handleDrag(event) {
      this.saveLabelPos(event.moved.element.id, event.moved.newIndex);
    },

    saveLineBreaks(label, type = 'annotation') {
      http.put(
        `dataset/label/${label.id}/`,
        { line_breaks: label.line_breaks },
        {
          params: {
            type,
          }
        }
      );
    },

    saveColumnLabel(label, type = 'annotation') {
      http.put(
        `dataset/label/${label.id}/`,
        { column_label: label.column_label },
        {
          params: {
            type,
          }
        }
      );
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          await http.post(`dataset/label/${this.datasetId}/`, {
            name: this.trimmedLabel,
          });
          this.newLabel = "";
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getLabels();
      }
    },

    deleteLocationLabel(id) {
      this.deleteLocation = true;
      this.deletingLabel = id;
      this.deleteLabelOn = true;
    },

    async deleteLabel() {
      try {
        await http.delete(`dataset/label/${this.deletingLabel}/`, {
          params: {
            type: this.deleteLocation && 'location' || 'annotation',
          },
        });
        this.deleteLabelOn = false;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
      if (this.deleteLocation) {
        const locLabelComponent = this.$refs['locationLabels'];
        if (locLabelComponent) {
          await locLabelComponent[locLabelComponent.length - 1].getLocationLabels();
        }
      } else {
        this.getLabels();
      }
      this.deleteLocation = false;
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    },

    showAll: {
      type: Boolean,
      required: true,
    }
  },

  emits: ['updateLabels'],
}
</script>

<style lang="scss" scoped>
.configure-labels {
  width: 800px;

  .label-container {
    background-color: white;
    width: 740px;
    font-size: 0.7rem;
    padding: 10px;
    position: relative;
    height: 50px;
    margin-left: 0px;
    color: #4c4c4c;
    border: #c8c8c8 2px solid;
    overflow: hidden;

    .icon-bg {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: white;
      width: 55px;
    }

    .label-buttons {
      position: absolute;
      right: 20px;
      top: 0px;
      bottom: 0px;
    }

    .close-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .edit-name-icon {
      position: absolute;
      right: 0px;
      top: 1px;
    }

    .edit-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: rgb(var(--v-theme-primary)) 1px solid;
      text-align: center;

      .edit-icon-icon {
        margin-top: -3px;
      }
    }

    .annotations-container {
      padding-top: 10px;
      margin-left: 210px;
      max-width: calc(100% - 270px);
      position: relative;
    }

    .label-name-container {
      position: absolute;
      width: 200px;
      left: 0px;
      top: 0px;
      bottom: 0px;
      padding: 10px;

      .label-name-container-inner {
        position: absolute;
        max-width: 200px;
        padding-right: 20px;

        .label-name-field {
          display: inline-block;
          text-transform: uppercase;
          background-color: #ffffff00;
          padding: 0 !important;
          border: none;
          width: 180px;
          border-bottom: 1px solid rgb(var(--v-theme-primary));

          &:focus {
            outline: none;
            border-bottom: 1px solid rgb(var(--v-theme-primary));
          }

          &:focus-visible {
            outline: none;
            border-bottom: 1px solid rgb(var(--v-theme-primary));
          }
        }
      }
    }
  }
}

.label-row {
  margin: 0 !important;
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  &:last-of-type {
    border-bottom: 0px solid #eee;
  }
}

.label-field {
  width: 230px;
}

.add-button {
  top: 3px;
  box-shadow: none !important;
}

.ghost-label {
  background-color: #dbd4fa !important;
}

.label-name-field {
  display: inline-block;
  text-transform: uppercase;
  background-color: #ffffff00;
  padding: 0 !important;
  border: none;
  width: 180px;
  border-bottom: 1px solid rgb(var(--v-theme-primary));

  &:focus {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  &:focus-visible {
    outline: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }
}
</style>
