<template>
  <div class="top-gap-lg">
    <TableActions
      type="deleted"
      :number-of-selected="selected.length"
      :create-condition="false"
      :delete-condition="false"
      @filter-change="(filter) => trimmedFilter = filter"
    >
      <template #actions>
        <TableAction
          v-if="selected.length > 0"
          prepend-icon="fas fa-history"
          :label="$tc('recycleBin.restore', { number: selected.length })"
          @click="handleRestore"
        />
        <TableAction
          v-if="selected.length > 0"
          prepend-icon="fas fa-trash"
          :label="$tc('datatable.header.delete', { number: selected.length })"
          @click="deleteDialog = true"
        />
        <TableAction
          v-if="workflows.length > 0 && selected.length === 0"
          button-variant="outlined"
          prepend-icon="fas fa-exclamation-triangle"
          :label="$t('deleted.delete_all')"
          @click="deleteDialog = true"
          show-text
        />
      </template>
    </TableActions>
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="workflows.length"
      :total="total"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @sort="(value) => sortDesc = value"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      sortable
    >
      <template #header>
        <v-col cols="auto">
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col>
          {{ $t('workflows.name') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in workflows"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col>
              <ItemName
                :key="item.id"
                :item="item"
                :clickable="false"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="deleteDialogTitle"
      :message="deleteDialogMessage"
      @confirm="handleDelete"
    >
      <template #extra-content>
        <div class="warning-message top-gap-sm">
          {{ $t('workflows.delete_warning') }}
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>
  
<script>
import _ from 'lodash';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { displaySnackbarSuccess } from '@/utils/SnackbarUtils';
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableAction from '@/components/common/elements/Tables/TableAction';
import ConfirmDialog from '@/components/common/elements/Tables/ConfirmDialog';

export default {
  name: 'DeletedWorkflows',

  components: {
    TableActions,
    TableAction,
    TableWithFooter,
    ItemName,
    ConfirmDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      deleteAllDialog: false,
      allSelected: false,
      workflows: [],
      total: 0,
      trimmedFilter: '',
      deleteDialog: false,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    selected() {
      return this.workflows.filter(item => item.selected);
    },

    deleteDialogTitle() {
      return this.selected.length > 0
        ? this.$t('recycleBin.delete_workflow_title')
        : this.$t('recycleBin.delete_workflow_all_title');
    },

    deleteDialogMessage() {
      return this.selected.length > 0
        ? this.$t('recycleBin.delete_workflow_confirm')
        : this.$t('recycleBin.delete_workflow_all_confirm');
    },
  },

  watch: {
    sortDesc() {
      this.getDeletedWorkflows();
    },

    total(total) {
      if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDeletedWorkflows();
    },

    currentPage(page) {
      this.handleCurrentPageChange(page);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDeletedWorkflows();
      }, 300
    ),
  },

  created() {
    this.getDeletedWorkflows();
  },

  methods: {
    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.workflows.forEach(w => {
        w.selected = false;
      });
    },

    toggleSelectAll() {
      this.workflows.forEach(item => {
        item.selected = this.allSelected;
      });
    },

    handleSelect() {
      this.allSelected = this.workflows.every(item => item.selected);
    },

    async handleRestore() {
      let restored = 0
      await Promise.all(this.selected.map(async workflow => {
        try {
          return await WorkflowAPI.restoreWorkflow(workflow.uuid);
        } catch (error) {
          return
        } finally {
          restored++;
        }
      }));
      displaySnackbarSuccess(this.$tc('workflows.restored_message', restored));
      this.finishDeletion();
    },

    handleCurrentPageChange(page) {
      this.allSelected = false;
      this.workflows.forEach(item => {
        item.selected = this.allSelected;
      });
      this.getDeletedWorkflows((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    async getDeletedWorkflows(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        const [workflows, total] = await WorkflowAPI.getByUUID(
          null,
          this.trimmedFilter,
          this.sortDesc,
          offset,
          limit,
          true,
        )
        this.workflows = workflows;
        this.total = total;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      if (this.selected.length > 0) {
        await Promise.all(this.selected.map(async workflow => {
          try {
            return await WorkflowAPI.deleteWorkflow(workflow.uuid);
          } catch (error) {
            return
          }
        }));
      } else {
        await WorkflowAPI.deleteArchived();
      }
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getDeletedWorkflows();
      const lastPage = Math.max(1, Math.ceil(this.total / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.handleCurrentPageChange(this.currentPage);
      this.deleteDialog = false;
    },


    hasPendingJobs(workflow) {
      return !!workflow.nb_pending_jobs;
    },
  },

  emits: ['changeTotal'],
}
</script>
<style lang="scss" scoped>
.warning-message {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: rgb(var(--v-theme-primary-lighten2));
  font-style: italic;
  font-size: 0.85rem;
}
</style>  
