<template>
  <v-card class="document-labels-config">
    <v-row class="font-weight-bold">
      <v-col
        class="ellipsis d-flex align-center"
        cols="5"
      >
        {{ $t('models.label') }}
      </v-col>
      <v-col class="ellipsis d-flex align-center">
        {{ $t('type') }}
      </v-col>
    </v-row>
    <div
      v-for="(label, i) in docLabels"
      :key="i"
      class="label-row"
    >
      <v-row
        style="height: 60px"
        @mouseover="hoveredLabel = label.id"
      >
        <v-icon
          class="delete-icon"
          color="black"
          size="14"
          @click="deleteLabelOn = true; deletingLabel = label.id;"
        >
          fas fa-times
        </v-icon>
        <v-col
          v-if="editedLabel !== label.id"
          class="name-col"
          cols="5"
        >
          <div
            class="ellipsis inline-middle mt-3"
            style="max-width: 210px"
          >
            {{ label.name }}
          </div>
          <v-tooltip
            v-if="hoveredLabel === label.id"
            bottom
          >
            <template #activator="{ props }">
              <v-icon
                class="clickable left-gap-sm inline-middle mt-3"
                size="12"
                v-bind="props"
                @mouseover="hoveredLabel = label.id"
                @click="startNameEdit(label.id, label.name)"
              >
                fas fa-pen
              </v-icon>
            </template>
            {{ $t("models.edit_name") }}
          </v-tooltip>
        </v-col>
        <v-col
          v-else
          class="name-col"
          cols="5"
        >
          <input
            ref="labelNameField"
            v-model="newNameForLabel"
            type="text"
            class="label-name-field mt-3"
            @keydown.enter="saveDocLabelName"
            @keydown.esc="editedLabel = -1"
          >
          <v-icon
            class="clickable edit-name-icon"
            size="12"
            @click="saveDocLabelName"
          >
            fas fa-check
          </v-icon>
        </v-col>
        <v-col
          class="ellipsis d-flex"
          style="margin-left: -13px"
        >
          <div
            v-if="label.options && label.options.length > 0"
            style="margin-top: -4px"
          >
            <span
              v-if="label.exclusive"
              class="label-type-description"
            >
              {{ $t('models.label_types.single_select_with') }}
            </span>
            <span
              v-else
              class="label-type-description"
            >
              {{ $t('models.label_types.multi_select_with') }}
            </span>
            <MaxWidthChip
              style="margin-top: 8px"
              :text-array="[label.options.length]"
            />
            <span class="right-gap-sm label-type-options">
              options
            </span>
            <v-tooltip
              v-if="expanded !== label.id"
              color="#423F4F"
              right
            >
              <template #activator="{ props }">
                <v-icon
                  class="clickable"
                  style="margin-top: -1px"
                  size="16"
                  v-bind="props"
                  @click="expanded = label.id"
                >
                  fas fa-chevron-right
                </v-icon>
              </template>
              <span style="color: white">
                {{ $t('show_all') }}
              </span>
            </v-tooltip>
            <v-icon
              v-else
              class="clickable"
              style="margin-top: -1px"
              size="16"
              @click="expanded = -1"
            >
              fas fa-chevron-down
            </v-icon>
          </div>
          <div
            v-else
            class="label-type-text"
          >
            {{ $t('models.label_types.text') }}
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="expanded === label.id"
        class="top-gap-sm label-expanded"
      >
        <v-col cols="5" />
        <v-col style="margin-left: 13px">
          <MaxWidthChip
            v-for="(option, j) in label.options"
            :key="j"
            :text-array="[option]"
          />
        </v-col>
      </v-row>
    </div>
    <ConfirmDialog
      v-model="deleteLabelOn"
      :title="$t('models.delete_label')"
      :message="$t('models.label_delete_confirmation')"
      @confirm="deleteLabel"
    />
  </v-card>
</template>

<script>
import { http } from '@/plugins/axios';

import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";

export default {
  name: 'DocumentLabelsConfig',

  components: {
    MaxWidthChip,
    ConfirmDialog,
  },

  data() {
    return ({
      expanded: -1,
      docLabels: [],
      hoveredLabel: -1,
      deleteLabelOn: false,
      deletingLabel: -1,
      editedLabel: -1,
      newNameForLabel: '',
    });
  },

  computed: {
    trimmedNewNameForLabel() {
      return this.newNameForLabel.trim();
    },
  },

  methods: {
    async deleteLabel() {
      try {
        await http.delete(`dataset/label/${this.deletingLabel}/?type=entry`);
        this.deleteLabelOn = false;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
      this.getDocLabels();
    },

    startNameEdit(id, name) {
      this.editedLabel = id;
      this.newNameForLabel = name;
      setTimeout(() => {
        this.$refs.labelNameField[0].focus();
      }, 100);
    },

    async saveDocLabelName() {
      if (this.trimmedNewNameForLabel !== '') {
        try {
          const newName = this.trimmedNewNameForLabel;
          await http.put(
            `dataset/label/${this.editedLabel}/?type=entry`,
            { name: newName },
          );
          this.editedLabel = -1;
          this.newNameForLabel = '';
        } catch (error) {
          error.handleGlobally && error.handleGlobally();
        }
        this.getDocLabels();
      }
    },

    async getDocLabels() {
      try {
        this.$store.commit("setLoadingScreen", true);
        const response = await http.get(
          `dataset/label/${this.datasetId}/?type=entry`
        );
        this.docLabels = response.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.$store.commit("setLoadingScreen", false);
      }
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.document-labels-config {
  .label-row {
    margin: 0 !important;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid #eee !important;

    .name-col {
      padding-top: 5px;
      margin-left: 0px;
    }

    .label-type-description {
      font-size: 0.9rem;
      margin-right: 3px;
      margin-left: 15px;
    }

    .label-type-options {
      font-size: 0.9rem;
      margin-left: -6px;
    }

    .label-type-text {
      font-size: 0.9rem;
      margin-top: 7px;
      margin-left: 15px;
    }

    .label-expanded {
      padding-top: 10px;
      background-color: #eee;
    }

    .label-name-field {
      display: inline-block;
      background-color: #ffffff00;
      padding: 0 !important;
      border: none;
      width: 180px;
      border-bottom: 1px solid rgb(var(--v-theme-primary));

      &:focus {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }

      &:focus-visible {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }
    }

    .delete-icon {
      position: absolute;
      right: 7px;
      top: 7px;
    }
  }
}
</style>
