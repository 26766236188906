<template>
  <v-expansion-panel>
    <v-expansion-panel-title
      class="text-uppercase"
      style="padding-left: 10px !important; border-bottom: #aaa 1px solid;"
      @click="disableHover"
    >
      <template #default>
        <div
          style="max-width: 50%"
        >
          <v-tooltip
            color="#423f4f"
            location="right"
          >
            <template #activator="{ props }">
              <div
                class="d-inline"
                v-bind="props"
              >
                <span class="ellipsis">
                  {{ name }}
                </span>
                <span>
                  [{{ index }}]
                </span>
              </div>
            </template>
            <div
              class="ellipsis"
              style="color: white"
            >
              {{ name }}
            </div>
          </v-tooltip>
          <v-tooltip
            color="#423F4F"
            location="right"
          >
            <template #activator="{ props }">
              <v-icon
                class="hashtag-icon d-inline"
                size="11"
                v-bind="props"
              >
                fas fa-hashtag
              </v-icon>
            </template>
            <span style="color: white">
              id: {{ subgroupId }}
            </span>
          </v-tooltip>
        </div>
        <div class="header-buttons">
          <v-icon
            v-if="origin === 'manual'"
            color="white"
            class="verify-button verify-button--enabled"
            style="display: inline-block; margin-right: 10px; padding-top: 9px"
            size="16"
            @click="handleDeleteGroup"
          >
            fas fa-trash
          </v-icon>
          <div style="margin-right: -7px; display: inline-block;">
            <VerifyButton
              :tooltip-message="allValid ? $t('verification.subgroup_verified') : $t('dataPoints.verify_group_message')"
              :correct="allValid && !values.every(v => v.prevalidated) && !loading"
              :prevalidated="
                values.every(v => v.prevalidated)
                  && values.every(v => v.status === 'valid')
                  && !loading
              "
              :loading="verifyLoading || loading"
              :disabled="verifyLoading || loading"
              @verify-click="handleVerifyGroup()"
            />
          </div>
        </div>
      </template>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div
        v-for="value in values"
        :key="value.id"
        class="value-row"
        :class="{
          'value-row__active':
            value.status !== 'invalid' && (highlighted(value.id) || editingCurrent(value.id)),
          'value-row--invalid__active':
            value.status === 'invalid' && (highlighted(value.id) || editingCurrent(value.id)),
        }"
        @mouseenter="handleHover(value)"
        @mouseleave="deHighlight(value.id)"
        @mousedown="deHighlight(value.id)"
      >
        <v-row
          class="fill-height ma-0"
          style="position: relative"
        >
          <v-col
            cols="3"
            class="px-0 text-uppercase data-point-name"
          >
            <div class="vertical-centered">
              {{ getValueLabel(value) }}
            </div>
          </v-col>
          <v-col
            v-if="loading"
            cols="5"
            class="value-div"
          >
            <v-skeleton-loader
              type="text"
              :width="`${Math.floor(Math.random() * (100 - 50 + 1) + 50)}%`"
            />
          </v-col>
          <v-col
            v-else
            cols="4"
            class="value-div"
          >
            <div v-if="!editingCurrent(value.id)">
              <div v-if="value.status === 'invalid'">
                {{ value.valid_value || "N/A" }}
                <v-tooltip location="right">
                  <template #activator="{ props }">
                    <div
                      style="width: fit-content"
                      v-bind="props"
                    >
                      {{ getNormalizedValue(value) }}
                    </div>
                  </template>
                  {{ $t('dataPoints.normalized_value') }}
                </v-tooltip>
              </div>
              <div
                v-if="value.status === 'invalid'"
                class="corrected-value"
              >
                {{ getLineThroughValue(value) }}
              </div>
              <div v-else>
                {{ getDisplayValue(value) }}
                <v-tooltip location="right">
                  <template #activator="{ props }">
                    <div
                      style="width: fit-content"
                      v-bind="props"
                    >
                      {{ getNormalizedValue(value) }}
                    </div>
                  </template>
                  {{ $t('dataPoints.normalized_value') }}
                </v-tooltip>
              </div>
            </div>
            <v-text-field
              v-else
              :ref="`input-${value.id}`"
              v-model="newValue"
              variant="outlined"
              color="primary"
              density="compact"
              @keyup.esc="$emit('cancelEdit')"
              @keydown.enter="verifyNewValue(value)"
            />
          </v-col>
          <v-col
            v-if="showLocationTooltip(value)"
            cols="auto"
            class="px-0"
          >
            <v-tooltip location="right">
              <template #activator="{ props }">
                <v-icon
                  v-bind="props"
                  style="opacity: 0.5;"
                  color="grey"
                  size="17"
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              {{ getLocationTooltipText(value) }}
            </v-tooltip>
          </v-col>
          <v-col cols="4" />
          <div class="verify-buttons">
            <div
              v-if="!editingCurrent(value.id)"
              class="vertical-centered d-flex"
              style="width: fit-content"
            >
              <v-tooltip
                v-if="value.prevalidated"
                location="right"
              >
                <template #activator="{ props }">
                  <v-icon
                    color="white"
                    class="verify-button"
                    :class="{
                      'verify-button--disabled': value && value.status === 'valid',
                      'verify-button--prevalidated-incorrect': value && value.status === 'invalid',
                    }"
                    v-bind="props"
                    @click="handleCorrection(value)"
                  >
                    fas fa-times
                  </v-icon>
                </template>
                <div v-if="value && value.status === 'invalid'">
                  <div style="font-size: 10px !important;">
                    {{ $t('dataPoints.prevalidated_message') }}
                  </div>
                  <div
                    v-for="explanation in getExplanations(value).filter(br => br && !br.isValid[subgroupId])"
                    :key="explanation.string"
                    style="font-size: 12px !important;"
                    class="br-feedback-invalid"
                  >
                    {{ explanation.string }}
                  </div>
                </div>
                <div v-else>
                  {{ $t('dataPoints.prevalidated_message') }}
                </div>
              </v-tooltip>
              <div v-else>
                <v-icon
                  color="white"
                  class="verify-button"
                  :class="{
                    'verify-button--enabled':
                      (value && value.status === 'pending') || !value,
                    'verify-button--disabled': value && value.status === 'valid',
                    'verify-button--incorrect':
                      value && value.status === 'invalid',
                  }"
                  @click="handleCorrection(value)"
                >
                  fas fa-times
                </v-icon>
              </div>
              <div style="margin-left: 10px; margin-right: -8px">
                <VerifyButton
                  :disabled="verifyLoading || loading"
                  :prevalidated="isPrevalidated(value)"          
                  :correct="isCorrect(value)"
                  :incorrect="isIncorrect(value)"
                  :loading="verifyLoading || loading"
                  :business-rule-strings="getExplanations(value)"
                  :show-tooltip="value.prevalidated && value.status === 'valid'"
                  @verify-click="verifyValue(value)"
                />
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-center vertical-centered"
            >
              <v-btn
                color="primary"
                style="width: 88px; box-shadow: none;"
                @click="verifyNewValue(value)"
                rounded
              >
                OK
              </v-btn>
            </div>
          </div>
        </v-row>
      </div>
      <div
        v-if="extractedLabels.length < labelMap.labels.length && addingLabel === ''"
        class="
          clickable
          text-body-2 text-decoration-underline
          primary--text
          text-right
          mt-2
        "
        @click="$emit('addMissingLabel', name, index)"
      >
        {{ $t("dataPoints.add_missing_label") }}
      </div>
      <AddMissingLabel
        v-if="addingLabel === `${name}-${fileId}-${index}`"
        :values="newSubgroupValues"
        :label-map="labelMap"
        :taken-labels="takenLabels"
        :index="index"
        :editing-label="editingLabel"
        @add-subgroup-label="(label) => $emit('addSubgroupLabel', label)"
        @edit-subgroup-label="(label) => $emit('editSubgroupLabel', label)"
        @save-label="$emit('saveLabel')"
        @delete-subgroup-label="(id) => $emit('deleteSubgroupLabel', id)"
        @cancel-add-label="$emit('cancelAddLabel')"
      />
    </v-expansion-panel-text>
    <ConfirmDialog
      v-model="deleteDialog"
      :title="$t('datatable.deleteSubgroup')"
      :message="$t('datatable.deleteConfirmationSubgroup')"
      @confirm="$emit('deleteSubgroup', subgroupId); deleteDialog = false;"
    />
  </v-expansion-panel>
</template>

<script>
import AddMissingLabel from "@/components/extract/elements/Validation/AddMissingLabel";
import VerifyButton from "@/components/extract/elements/Validation/VerifyButton";
import ConfirmDialog from "@/components/common/elements/Tables/ConfirmDialog";

import format_mixin from "@/mixins/format";

export default {
  name: "GroupValidator",

  mixins: [format_mixin],

  components: {
    AddMissingLabel,
    ConfirmDialog,
    VerifyButton,
  },

  data() {
    return {
      deleteDialog: false,
      forceCompute: 10,
      hoverDisabled: false,
      newValue: "",
      verifyLoading: false,
    };
  },

  computed: {
    takenLabels() {
      return this.values.map((item) => item.label);
    },
    extractedLabels() {
      const labels = this.values.map((value) => value.label);
      return [...new Set(labels)];
    },
    allValid() {
      this.forceCompute;
      return this.values.every(v => !v.prevalidated) && (
        (this.values.every(value => value.status === 'valid'))
      );
    },
    somePending() {
      return this.values.some(value => value.status === 'pending');
    },
  },

  methods: {
    isCorrect(value) {
      return value
        && !value.prevalidated
        && value.status === 'valid'
        && !this.loading 
        && !this.verifyLoading;
    },

    showLocationTooltip(value) {
      if (!value.is_generative) {
        return false;
      }
      return value.possible_locations?.length !== 1;
    },

    isIncorrect(value) {
      return value
        && !value.prevalidated
        && value.status === 'invalid'
        && !this.loading 
        && !this.verifyLoading;
    },

    isPrevalidated(value) {
      return value
        && value.prevalidated
        && value.status === 'valid'
        && !this.loading
        && !this.verifyLoading;
    },

    getLineThroughValue(value) {
      return value.value || "N/A";
    },

    getDisplayValue(value) {
      return value.valid_value || value.value || "N/A";
    },

    disableHover() {
      this.hoverDisabled = true;
      setTimeout(() => {
        this.hoverDisabled = false;
      }, 300);
    },

    editingCurrent(id) {
      return this.editing === id;
    },

    handleCorrection(value) {
      if (value.status === 'invalid') {
        this.$emit('cancelGroupValue', value);
        return;
      }
      this.newValue = value.value;
      this.$emit("editValue", value);
      setTimeout(() => {
        this.$refs[`input-${value.id}`][0].focus();
      }, 100);
    },

    verifyNewValue(value) {
      const newValue = {...value};
      newValue.value = this.newValue;
      this.$emit("verifyValue", newValue);
    },

    verifyValue(value) {
      if (value.status === 'valid') {
        this.$emit('cancelGroupValue', value);
        return;
      }
      this.$emit("verifyValue", value);
    },

    async handleVerifyGroup() {
      this.verifyLoading = true;
      if (this.allValid) {
        await this.cancelGroup(this.values);
      } else {
        await this.verifyGroup(this.values);
      }
      this.verifyLoading = false;
      this.forceCompute += 5;
    },

    handleDeleteGroup(event) {
      event.stopPropagation();
      if (this.origin === 'manual') {
        this.deleteDialog = true;
      }
    },

    highlighted(id) {
      return (
        this.activeValue.value &&
        this.activeValue.value.id &&
        this.activeValue.value.id === id
      );
    },

    handleHover(value) {
      if (!this.hoverDisabled) {
        this.$emit("highlight", value);
      }
    },

    deHighlight(id) {
      if (!this.editingCurrent(id)) {
        this.$emit("deHighlight");
      }
    },

    getExplanations(value) {
      const code = `eg_${this.groupId}_${value.label}_${this.subgroupId}`;
      return this.businessRuleStrings[code] || [];
    },

    getLocationTooltipText(value) {
      if (value && value.possible_locations) {
        return value.possible_locations.length === 0
          ? this.$t('dataPoints.no_location_was_found')
          : this.$t('dataPoints.multiple_locations_was_found');
      }
      return '';
    },
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    fileId: {
      type: Number,
      required: true,
    },

    values: {
      type: Array,
      required: true,
    },

    labelMap: {
      type: Object,
      default: () => {},
    },

    addingLabel: {
      type: String,
      default: "",
    },

    newSubgroupValues: {
      type: Array,
      default: () => [],
    },

    editingLabel: {
      type: String,
      required: true,
    },

    activeValue: {
      type: Object,
      default: () => ({}),
    },

    editing: {
      type: Number,
      required: true,
    },

    origin: {
      type: String,
      required: true,
    },

    subgroupId: {
      type: Number,
      required: true,
    },

    verifyGroup: {
      type: Function,
      required: true,
    },

    cancelGroup: {
      type: Function,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    groupId: {
      type: Number,
      required: true,
    },

    businessRuleStrings: {
      type: Object,
      default: () => {},
    },
  },

  emits: [
    'cancelEdit',
    'addMissingLabel',
    'addSubgroupLabel',
    'editSubgroupLabel',
    'saveLabel',
    'deleteSubgroupLabel',
    'cancelAddLabel',
    'deleteSubgroup',
    'cancelGroupValue',
    'editValue',
    'verifyValue',
    'highlight',
    'deHighlight',
  ],
};
</script>

<style lang="scss" scoped>
.value-row {
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid transparent;
  &:not(.value-row:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid #aaa;
  }
  &__active {
    padding-bottom: 8px !important;
    border: 2px solid $pending-base !important;
  }
  &--invalid__active {
    box-sizing: border-box;
    padding: 8px;
    padding-bottom: 8px !important;
    border: 2px solid $invalid-base !important;
  }
}

.value-row-light {
  box-sizing: border-box;
  padding: 8px;
  border: 2px solid transparent;
  &:not(.value-row-light:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid rgb(207, 207, 207);
  }
  &__active {
    padding-bottom: 8px !important;
    border: 2px solid $pending-base !important;
  }
}

.data-point-name {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  position: relative;
}

.hashtag-icon {
  color: rgb(var(--v-theme-grey-darken2)) !important;
  margin-left: 5px;
  top: -2px;
}

.hashtag-icon:hover {
  color: rgb(var(--v-theme-primary)) !important;
}

.header-buttons {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translate(0, -50%);
}

.value-div {
  font-size: 14px;
  line-height: 1.5rem;
}

.value-input {
  resize: none;
  width: 100%;
  border-radius: 5px;
  &:focus {
    outline: none;
    background-color: #eee;
  }
}

.br-feedback-invalid {
  color: $invalid-base !important;
}

.verify-buttons {
  position: absolute;
  right: 26px;
  top: 50%;
}

.verify-button {
  cursor: pointer;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;

  &--correct {
    background-color: $valid-base !important;
    cursor: default;
  }

  &--incorrect {
    background-color: $invalid-base !important;
    cursor: default;
  }

  &--enabled {
    background-color: #555 !important;
  }

  &--disabled {
    background-color: #ddd !important;
  }

  &--prevalidated-incorrect {
    background-color: $prevalidated-incorrect !important;
  }
}

.corrected-value {
  font-size: 12px;
  color: #aaa;
  text-decoration: line-through;
}
</style>
