import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1';

export class ClassifyModelAPI {

  static create({name, datasetIds}) {
    return http
      .post(`${BASE_PATH}/classification-models`, {
        name,
        dataset_ids: datasetIds,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getClassificationModels({
    offset = 0,
    limit = 20,
    descendingSort = true,
    hasTrained = null,
    classificationModelsType = null,
  }) {
    return http
      .get(`${BASE_PATH}/classification-models`, {
        params: {
          offset,
          limit,
          descending_sort: descendingSort,
          has_trained: hasTrained,
          classification_models_type: classificationModelsType,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getModel({modelId}) {
    return http
      .get(`${BASE_PATH}/classification-models/${modelId}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteModel({modelName}) {
    return http
      .delete(`${BASE_PATH}/models/?name=${modelName}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getAllModels() {
    return http
      .get(`${BASE_PATH}/models/list`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getStats({modelId}) {
    return http
      .get(`${BASE_PATH}/classification-models/${modelId}/statistics`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getConfusion({modelId}) {
    return http
      .get(`${BASE_PATH}/classification-models/${modelId}/confusion_matrix`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
