<template>
  <div
    class="resources-view"
    @dragenter="dragInsideEnter"
    @dragover="dragInsideEnter"
    @dragleave="inside = false; dragging = false;"
    @drop="e => dropFile(e, supportedExtensions)"
  >
    <ResourcesTable
      v-if="!dragging"
      ref="resourcesTable"
      @select-files="selectFiles"
      @change-folder="id => currentFolderId = id"
    />
    <v-card
      v-else
      class="upload-card pa-4 mt-4"
    >
      <div
        class="
          d-flex
          flex-column
          justify-center
          align-center
          upload-container
        "
        :class="{ 'upload-container__active': inside }"
      >
        <div
          class="text-h3"
          :class="{
            'upload-container__active': inside,
            'primary--text': !inside,
          }"
        >
          {{ $t("dropFile") }}
        </div>
      </div>
    </v-card>
    <FileInput
      ref="uploader"
      :supported-extensions="supportedExtensions"
      @change="uploadRefresh"
    />
  </div>
</template>

<script>
import { WorkflowResourcesAPI } from '@/API/workflows/WorkflowResourcesAPI';

import file_mixin from "@/mixins/file.js";
import ResourcesTable from "@/components/extract/views/Workflows/ResourcesTable";
import FileInput from '@/components/common/elements/Forms/FileInput';

export default {
  name: 'ResourcesView',

  mixins: [file_mixin],

  components: {
    ResourcesTable,
    FileInput,
  },

  data() {
    return ({
      dragging: false,
      inside: false,
      currentFolderId: null,
      supportedExtensions: [],
    });
  },

  async created() {
    const data = await WorkflowResourcesAPI.getSupportedExtensions();
    this.supportedExtensions = data.extensions.map(extension => `.${extension}`);
  },

  methods: {
    handleDrop(files) {
      this.uploadRefresh(files);
    },

    async uploadRefresh(files) {
      await this.uploadFiles(files);
      this.$refs.resourcesTable.getResources(this.currentFolderId);
    },

    async startUpload(file) {
      try {
        await WorkflowResourcesAPI.uploadFile(file, this.currentFolderId);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    selectFiles() {
      this.$refs.uploader.click();
    },
  },
}
</script>

<style lang="scss" scoped>
  .resources-view {
    .upload-card {
      width: 100%;
      height: 500px;
    }

    .upload-container {
      width: 100%;
      height: 100%;
      border: 2px dashed rgb(var(--v-theme-primary-lighten1));

      &__active {
        background-color: rgb(var(--v-theme-primary-lighten2));
        color: rgb(var(--v-theme-primary-lighten1));
      }
    }
  }
</style>
