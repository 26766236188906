<template>
  <v-dialog
    v-model="openDialog"
    max-width="450"
    @click:outside="openDialog = false"
    @keydown.esc="openDialog = false"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-8">
        {{ $t('workflows.resources.create_folder') }}
      </h2>
      <v-text-field
        v-model="folderName"
        variant="outlined"
        color="primary"
        density="compact"
        prepend-icon="fas fa-folder"
        :placeholder="$t('workflows.resources.folder_name')"
        :hint="nameValid ? '' : `${$t('forms.folder_name_hint')} ${$t('forms.folder_unique_hint')}`"
        @keydown.enter="createFolder"
      />
      <div class="mt-4 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="openDialog = false"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            :disabled="!nameValid"
            @click="createFolder"
            block
            rounded
          >
            {{ $t('confirm') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { isResourceNameValid } from '@/utils/FormValidation';

export default {
  name: 'CreateFolderDialog',

  data() {
    return ({
      openDialog: this.modelValue,
      folderName: '',
    });
  },

  computed: {
    nameValid() {
      return isResourceNameValid(this.folderName.trim());
    },
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
      this.folderName = '';
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  methods: {
    createFolder() {
      if (this.nameValid) {
        this.$emit('confirm', this.folderName.trim());
        this.openDialog = false;
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['confirm', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
