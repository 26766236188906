<template>
  <div class="py-7">
    <div class="classification-agent-actions-container">
      <v-text-field
        v-model="searchedClassificationAgentName"
        class="right-gap name-filter-field inline-middle"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('filter')"
        @input="onNameFilterInput"
      />
    </div>
    <TableWithFooter
      :loading="false"
      :paginated-items-length="classificationAgents.length"
      :total="totalClassificationAgents"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      @sort="onSort"
      sortable
    >
      <template #header>
        <v-col cols="3">
          {{ $t('classificationAgents.classification_agent') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="classificationAgent in classificationAgents"
            :key="classificationAgent.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="3">
              <ItemName
                :key="classificationAgent.id"
                type="classificationAgent"
                class="inline-top"
                :item="classificationAgent"
                @click="openClassificationAgentDocumentsTable(classificationAgent.id)"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
  </div>
</template>

<script>

import ItemName from '@/components/common/elements/General/ItemName.vue';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';


export default {
  name: 'ClassificationAgentsView',

  components: {
    ItemName,
    TableWithFooter,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      classificationAgents: [],
      totalClassificationAgents: 0,
      itemsPerPage,
      currentPage,
      searchedClassificationAgentName: '',
      descendingSort: true,
    };
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
      this.fetchClassificationAgents();
    },

    currentPage() {
      this.fetchClassificationAgents();
    }
  },

  created() {
    this.fetchClassificationAgents();
  },

  methods: {
    async fetchClassificationAgents() {
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const searchedClassificationAgentName = this.searchedClassificationAgentName.trim().toLowerCase();
      const response = await ClassificationAgentsAPI.fetchAll({
        name: searchedClassificationAgentName,
        offset,
        limit: this.itemsPerPage,
        sortDesc: this.descendingSort,
        hasHITLDocuments: true,
        classificationModelType: 'document_models',
      });

      this.classificationAgents = response.data;
      this.totalClassificationAgents = parseInt(response.headers['x-total-count'], 10);
    },

    async onNameFilterInput() {
      this.resetCurrentPage();
      await this.fetchClassificationAgents();
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    openClassificationAgentDocumentsTable(classificationAgentId) {
      this.$router.push({
        name: 'ClassificationAgentDocuments',
        params: { id: classificationAgentId },
      });
    },

    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchClassificationAgents();
    },

  },
}
</script>

<style lang="scss" scoped>

.classification-agent-actions-container {
  display: inline-flex;
  gap: .25em;
  align-items: start;
}

.name-filter-field {
  width: 300px;
}

</style>
