<template>
  <ButtonWithIcon
    v-if="!fileName"
    class="top-gap-sm"
    color="primary"
    icon="fas fa-plus"
    :message="$t('settings.search.upload_file')"
    :disabled="!editingAllowed"
    @click="selectFile"
    rounded
  />
  <div v-else>
    <div class="label text-field-label bottom-gap-sm right-gap-sm">
      {{ $t(`settings.search.metadata.current_file`) }}
    </div>
    <v-text-field
      v-model="fileName"
      variant="outlined"
      :disabled="!editingAllowed"
      hide-details
      readonly
    >
      <template #prepend-inner>
        <v-icon
          class="inline-middle"
          style="opacity: 1 !important;"
          color="primary"
          size="17"
        >
          fas fa-file-excel
        </v-icon>
      </template>
      <template #append>
        <div class="d-flex align-center">
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                class="inline-middle clickable mr-2"
                color="primary"
                @click="downloadFile"
              >
                fas fa-download
              </v-icon>
            </template>
            {{ $t('settings.search.download_file') }}
          </v-tooltip>
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                class="inline-middle clickable"
                color="primary"
                @click="selectFile"
              >
                fas fa-upload
              </v-icon>
            </template>
            {{ $t('settings.search.upload_another_file') }}
          </v-tooltip>
        </div>
      </template>
    </v-text-field>
  </div>
  <div
    v-for="key in Object.keys(params)"
    :key="key"
  >
    <div
      class="label text-field-label top-gap"
      :style="{
        opacity: fileName ? 1 : 0.5,
      }"
    >
      {{ $t(`settings.search.metadata.${key}`) }}
    </div>
    <v-text-field
      v-model="params[key]"
      class="mt-0"
      color="primary"
      density="compact"
      variant="outlined"
      :placeholder="key === 'ignore_columns' && 'col1, col2, col3...' || null"
      :disabled="!fileName || !editingAllowed"
      :rules="key === 'path_column' && validationRules || []"
      :hide-details="key !== 'path_column'"
    />
  </div>
  <div class="top-gap text-right">
    <v-btn
      color="primary"
      :disabled="!fileName || !(params.path_column) || !editingAllowed"
      @click="save"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
  </div>
  <FileInput
    ref="uploader"
    type="searchMetadata"
    @change="handleFileSelected"
  />
</template>
<script>
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import FileInput from '@/components/common/elements/Forms/FileInput.vue';

export default {
  name: 'SearchMetadataSettings',

  components: {
    ButtonWithIcon,
    FileInput,
  },

  data() {
    return {
      file: null,
      fileName: '',
      params: {
        path_column: '',
        titre_column: '',
        ignore_columns: '',
        separator: '',
      },
      validationRules: [
        (value) => !!value || this.$t('forms.required'),
      ],
      isUpdating: false,
    };
  },

  watch: {
    async orgId() {
      await this.getMetadata();
    }
  },

  async created() {
    await this.getMetadata();
  },

  methods: {
    selectFile() {
      this.$refs.uploader.click();
    },

    async getMetadata() {
      try {
        const metadata = await SearchConfigAPI.getMetadata(this.orgId);
        if (metadata) {
          this.isUpdating = true;
          const {
            name: fileName,
            path_column,
            titre_column,
            ignore_columns,
            separator,
          } = metadata;
          this.fileName = fileName;
          this.params = {
            path_column,
            titre_column,
            ignore_columns: ignore_columns.join(', '),
            separator,
          };
        }
      } catch(_) {
        this.isUpdating = false;
        this.file = null,
        this.fileName = ''
        this.params = {
          path_column: '',
          titre_column: '',
          ignore_columns: '',
          separator: '',
        }
      }
    },

    handleFileSelected([file]) {
      this.isUpdating = !!this.fileName;
      this.file = file;
      this.fileName = file.name;
    },

    async save() {
      try {
        if (this.isUpdating) {
          await SearchConfigAPI.updateMetadata(this.file, this.params);
          this.$store.commit('setSuccessMessage', this.$t('settings.search.metadata.metadata_updated'));
        } else {
          await SearchConfigAPI.uploadMetadata(this.file, this.params);
          this.$store.commit('setSuccessMessage', this.$t('settings.search.metadata.metadata_uploaded'));
        }
        this.$refs.uploader.value = '';
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async downloadFile() {
      try {
        const response = await SearchConfigAPI.downloadMetadata();
        // Create a blob from the response data
        const blob = new Blob([response.data], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        // Create a link and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },

  props: {
    orgId: {
      type: [Number, null],
      required: true,
    },

    editingAllowed: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
