<template>
  <v-dialog
    v-model="showDialog"
    max-width="750"
    height="700"
  >
    <v-card class="dialog-card pb-5">
      <div class="d-flex justify-end">
        <v-icon
          class="close-icon"
          size="16px"
          @click="showDialog = false"
        >
          fas fa-times
        </v-icon>
      </div>
      <h2 class="dialog-title">
        {{ title }}
      </h2>
      <v-textarea
        ref="reviewerCommentInput"
        v-model="localReviewerComment"
        class="review-comment-input top-gap-sm"
        variant="outlined"
        :messages="$t('workflows.reviewer_comment_tip')"
        :placeholder="$t('workflows.reviewer_comment_placeholder')"
        @keyup.ctrl.enter="saveReviewerComment"
        no-resize
      />
      <div class="d-flex justify-end top-gap-sm">
        <v-btn
          class="clickable mr-3"
          color="primary"
          variant="outlined"
          @click="showDialog = false"
          rounded
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="clickable"
          color="primary"
          :disabled="!localReviewerComment.trim()"
          @click="saveReviewerComment"
          rounded
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ReviewerCommentDialog',

  data() {
    return {
      showDialog: this.modelValue,
      localReviewerComment: this.reviewerComment,
    };
  },

  watch: {
    modelValue(show) {
      this.showDialog = show;
    },

    showDialog(show) {
      if (show) {
        this.$nextTick(() => {
          this.$refs.reviewerCommentInput && this.$refs.reviewerCommentInput.focus();
        });
      }
      this.$emit('update:modelValue', show);
    },
  },

  methods: {
    saveReviewerComment() {
      this.$emit('update:reviewer-comment', this.localReviewerComment);
      this.showDialog = false;
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    reviewerComment: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue', 'update:reviewer-comment'],
};
</script>
  