<template>
  <div class="create-dataset">
    <BackLink
      :text="$t('models.all_datasets')"
      @click="handleBack"
    />
    <v-radio-group
      v-model="creationMethod"
      class="top-gap-sm"
      :disabled="files.length > 0"
      inline
    >
      <div
        v-for="(method, i) in creationMethods"
        :key="i"
        class="inline-middle radio-box right-gap-sm"
      >
        <v-radio
          :label="$t(`datasets.createMethods.${method}`)"
          :value="method"
        />
      </div>
    </v-radio-group>
    <div>
      <v-text-field
        v-if="creationMethod === 'documents'"
        v-model="dataset.name"
        class="name-field"
        variant="outlined"
        color="primary"
        density="compact"
        :hint="nameValid ? '' : `${$t('forms.name_hint')} ${$t('forms.unique_hint')}`"
        :placeholder="$t('models.type_new_dataset_name')"
        @keydown.enter="saveDataset"
      />
      <DatasetOptions
        type="create"
        :dataset="dataset"
        @change="updateDataset"
      />
    </div>
    <v-btn
      v-if="creationMethod === 'documents'"
      class="top-gap-sm"
      style="top: -2px; box-shadow: none"
      color="primary"
      :disabled="!nameValid"
      @click="saveDataset"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
    <InfoBox class="top-gap bottom-gap">
      <span v-if="creationMethod === 'documents'">
        {{ $t('models.min_upload_message', { number: $store.getters.MIN_ENTRIES }) }}
      </span>
      <span v-else>
        {{ $t('datasets.createMethods.zip_message') }}
      </span>
    </InfoBox>
    <FileUploader
      ref="fileUploader"
      :supported-extensions="supportedExtensions"
      @files-selected="handleFilesSelect"
    />
  </div>
</template>

<script>
import { EntryAPI } from '@/API/extract/EntryAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { http } from '@/plugins/axios';
import { isNameValid } from '@/utils/FormValidation';
import { displaySnackbarSuccess, displaySnackbarWarning, displaySnackbarError } from '@/utils/SnackbarUtils';
import { ConfigAPI } from '@/API/extract/ConfigAPI';

import FileUploader from '@/components/common/elements/Forms/FileUploader';
import DatasetOptions from '@/components/extract/elements/Datasets/DatasetOptions';
import BackLink from '@/components/common/elements/Navigation/BackLink';
import InfoBox from '@/components/common/elements/General/InfoBox';

export default {
  name: 'CreateDataset',

  components: {
    FileUploader,
    DatasetOptions,
    BackLink,
    InfoBox,
  },

  data() {
    return ({
      files: [],
      dataset: {
        name: '',
        custom_reading_order: 'roma2',
        force_ocr: true,
        use_tables: false,
        use_deskew: true,
        detect_checkboxes: false,
        straighten_pages: true,
      },
      creationMethods: ['documents', 'zip'],
      creationMethod: 'documents',
    })
  },

  computed: {
    nameValid() {
      return isNameValid(this.dataset.name);
    },

    supportedExtensions() {
      return this.creationMethod === 'documents'
        ? this.$store.getters.acceptedDocDatasetFiles.split(', ')
        : ['.zip'];
    },
  },

  async mounted() {
    const config = await ConfigAPI.get()
    this.dataset.straighten_pages = (config.ocr_model === 'GOOGLE' || config.ocr_model === 'AZURE');
  },

  methods: {
    handleBack() {
      this.$emit('goBack');
    },

    async saveDataset() {
      if (this.nameValid) {
        let success = false;
        if (!this.dataset.id) {
          success = await this.addNew();
        } else {
          success = await this.editNew();
        }
        if (success) {
          await this.uploadFiles();
        }
      }
    },

    handleFilesSelect(files) {
      this.files = files;
      if (this.creationMethod === 'documents') {
        this.saveDataset();
      } else {
        this.uploadZipFiles();
      }
    },

    async editNew() {
      try {
        await http.put(
          `dataset/${this.dataset.id}`, this.dataset,
        );
        return true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
        return false;
      }
    },

    async addNew() {
      try {
        const response = await http.post(
          'dataset/', this.dataset
        );
        this.dataset = {...this.dataset, id: response.data};
        return true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
        return false;
      }
    },

    async uploadDocument(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await EntryAPI.post(this.dataset.id, file);
        return true;
      } catch (error) {
        return false;
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async uploadZip(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await DatasetAPI.uploadZip(file, this.dataset);
        return { success: true };
      } catch (error) {
        return {
          success: false,
          error,
        };
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async uploadFiles() {
      const length = this.files.length;
      const successUploads = [];
      if (length > 0) {
        if (typeof this.files === 'object') {
          this.files = Object.values(this.files).map(item => item);
        }
        for (let i = 0; i < length; i++) {
          successUploads.push(await this.uploadDocument(this.files[i]));
        }
        this.$emit('selectDataset', this.dataset);
      }
      const errorCount = successUploads.filter(success => !success).length;
      if (errorCount > 0) {
        if (errorCount === length) {
          displaySnackbarWarning(this.$t('datasets.upload_warning_all'));
        } else {
          displaySnackbarWarning(this.$t('datasets.upload_warning_some'));
        }
      } else {
        displaySnackbarSuccess(this.$t('models.saved_dataset'));
      }
    },

    async uploadZipFiles() {
      const length = this.files.length;
      const successUploads = [];
      if (length > 0) {
        if (typeof this.files === 'object') {
          this.files = Object.values(this.files).map(item => item);
        }
        for (let i = 0; i < length; i++) {
          successUploads.push(await this.uploadZip(this.files[i]));
        }
      }
      const errorCount = successUploads.filter(success => !success.success).length;
      if (errorCount > 0) {
        if (errorCount === length) {
          if (errorCount === 1 && successUploads[0].error.handleGlobally) {
            successUploads[0].error.handleGlobally();
          } else {
            displaySnackbarError(this.$t('datasets.zip_upload_failed'));
          }
          this.files = [];
          this.$refs.fileUploader.reset();
        } else {
          displaySnackbarWarning(this.$t('datasets.zip_upload_warning_some'));
          this.$emit('goBack');
        }
      } else {
        displaySnackbarSuccess(this.$t('datasets.dataset_creation_started'));
        this.$emit('goBack');
      }
    },

    updateDataset() {
      if (this.dataset.force_ocr === false) {
        this.dataset.straighten_pages = false;
        this.dataset.use_deskew = false;
      }
    }
  },

  emits: ['goBack', 'selectDataset'],
}
</script>

<style lang="scss" scoped>
.create-dataset {
  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }
}
</style>
