import { http } from '@/plugins/axios';

const BASE_PATH = 'search/api/v1/config/';
const METADATA_PATH = 'search/api/v1/metadata/';

export class SearchConfigAPI {
  static get(orgId = null) {
    return http
      .get(`${BASE_PATH}${orgId ? `?org_id=${orgId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static update(config) {
    return http
      .put(BASE_PATH, config)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getRoot(orgId = null) {
    return http
      .get(`${BASE_PATH}root/${orgId ? `?org_id=${orgId}` : ''}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getOptions() {
    return http
      .get(`${BASE_PATH}options/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getMetadata(orgId = null) {
    return http
      .get(`${METADATA_PATH}${orgId ? `?org_id=${orgId}` : ''}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  }

  static uploadMetadata(file, params) {
    const formData = new FormData();
    formData.append('file', file);
    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
      params,
    }
    return http
      .post(METADATA_PATH, formData, options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static updateMetadata(file, params) {
    let formData = {};
    let headers = {};
    if (file) {
      formData = new FormData()
      headers = { 'Content-Type': 'multipart/form-data' },
      formData.append('file', file);
    }
    const options = {
      headers,
      params,
    }
    return http
      .patch(METADATA_PATH, formData, options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static uploadSynonyms(file) {
    const formData = new FormData();
    formData.append('file_in', file);
    const options = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return http
      .post(`${BASE_PATH}synonyms/`, formData, options)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteSynonyms() {
    return http
      .delete(`${BASE_PATH}synonyms/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static downloadMetadata(orgId = null) {
    return http
      .get(`${METADATA_PATH}download`, {
        responseType: 'arraybuffer', 
        params: orgId ? { org_id: orgId } : {}
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
