<template>
  <div class="folder-breadcrumbs">
    <div
      ref="foldersContainer"
      class="d-flex"
    >
      <v-icon
        style="margin-top: 1px"
        color="primary"
        size="22"
      >
        fas fa-folder
      </v-icon>
      <div
        v-for="(folder, folderIndex) in path"
        :key="folder.id"
      >
        <div
          class="ml-2 folder-name"
          :class="{ clickable: isClickable(folderIndex) }"
          @click="handleFolderClick(folderIndex)"
        >
          {{ folder.name }} /
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FolderBreadcrumbs',

  methods: {
    isClickable(folderIndex) {
      return folderIndex < this.path.length - 1 && this.path[folderIndex].id > -1;
    },

    handleFolderClick(folderIndex) {
      if (this.isClickable(folderIndex)) {
        this.$emit('folder-click', folderIndex);
      }
    },
  },

  props: {
    path: {
      type: Array,
      required: true,
    },
  },

  emits: ['folder-click'],
};
</script>

<style lang="scss" scoped>
.folder-breadcrumbs {
  background-color: rgb(var(--v-theme-grey-lighten2));
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  height: 40px;
  margin-top: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .folder-name {
    margin-right: 3px;
    white-space: nowrap;
  }

  .clickable {
    color: rgb(var(--v-theme-primary));
  }
}
</style>
