<template>
  <div class="classifier-info page-padding py-7">
    <div
      class="clickable bottom-gap"
      @click="$router.push('/suite/studio/models/classification')"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('classifiers.all') }}
      </h4>
    </div>
    <HeaderName
      :item="classifcationModel"
      :editable="false"
      :show-id="false"
    />
    <v-card class="elevation-6 pa-0 top-gap">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col>
            {{ $t('classifiers.categories') }}
          </v-col>
          <v-col class="statitics-table-header__cell">
            {{ $t('classifiers.initial') }}
          </v-col>
          <v-col class="statitics-table-header__cell">
            {{ $t('classifiers.training_score') }}
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="statsLoaded && stats.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in stats"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col :ref="`stat${id}`">
            <div :style="{ width: item.width ? `${item.width}px` : '250px' }">
              <ItemName
                :item="item"
                :show-id="false"
                :clickable="false"
              />
            </div>
          </v-col>
          <v-col class="statistics-table__cell">
            {{ item.initial }}
          </v-col>
          <v-col class="statistics-table__cell">
            {{ item.initial ? `${(item.score * 100).toFixed(2)}%` : '-' }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div class="top-gap">
      {{ $t('classifiers.confusion') }}
    </div>
    <v-card
      class="elevation-6 pa-0 top-gap"
      style="overflow-x: auto"
    >
      <v-table
        style="table-layout: fixed"
        dense
      >
        <v-container
          class="pa-0 table-row-height"
          fluid
        >
          <v-row class="table-row table-row__header table-row-height no-wrap-row">
            <v-col class="first_col">
              {{ $t('classifiers.wait_predict') }}
            </v-col>
            <v-col
              v-for="category in confusion"
              :key="category.name"
            >
              <div
                class="d-flex justify-center"
                :style="{
                  'width': category.width ? `${category.width}px` : '50px',
                }"
              >
                <ItemName
                  class="category-name"
                  :item="category"
                  :show-id="false"
                  :clickable="false"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div
          v-if="confusionLoaded && confusion.length === 0"
          class="table-row fade-in table-row-height"
          style="text-align: center; padding-top: 15px; opacity: 0.5"
        >
          <i>{{ $t('docTypes.no_results') }}</i>
        </div>
        <v-container
          v-else
          class="pa-0"
          fluid
        >
          <v-row
            v-for="(row, rowIndex) in confusion"
            :key="row.name"
            class="table-row fade-in table-row-height no-wrap-row"
          >
            <v-col class="first_col">
              <ItemName
                :item="row"
                :show-id="false"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-for="col in row.scores"
              :key="col.name"
              :ref="`${col.name}${rowIndex}`"
              :style="{ 'min-width': `${row.minWidth}px` }"
            >
              <v-menu
                v-if="getMisclassifiedFilesPerCell(row.name, col.name).length > 0"
                close-delay="100"
                open-on-hover
              >
                <template #activator="{ on, props }">
                  <div
                    v-bind="props"
                    :on="on"
                    :class="['confusion-col text-center', getConfusionColClass(col, row)]"
                    :style="{ 'max-width': getDisplayNumberMaxWidth(row) }"
                  >
                    {{ col.score }}
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="file in getMisclassifiedFilesPerCell(row.name, col.name)"
                    :key="file"
                    :class="{'misclassified-file-item': file !== null && typeof file === 'object'}"
                  >
                    <ItemName
                      name-field="filename"
                      class="inline-top"
                      :clickable="isFileLinkable(file)"
                      :show-id="isFileLinkable(file)"
                      :item="isFileLinkable(file) ? file : { filename: file }"
                      @name-click="onMisclassifiedFileClick(file)"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
              <template v-else>
                <div
                  :class="['confusion-col text-center', getConfusionColClass(col, row)]"
                  :style="{ 'max-width': getDisplayNumberMaxWidth(row) }"
                >
                  <p>
                    {{ col.score }}
                  </p>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-table>
    </v-card>
  </div>
</template>

<script>
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';


import HeaderName from '@/components/common/elements/General/HeaderName';
import ItemName from '@/components/common/elements/General/ItemName.vue';

export default {
  name: 'ClassifierInfo',

  components: {
    HeaderName,
    ItemName,
  },

  constants: {
    RENDER_TIMEOUT: 100,
    MENU_TIMEOUT: 300,
  },

  data() {
    return ({
      stats: [],
      confusion: [],
      misclassifiedFiles: {},
      statsLoaded: false,
      confusionLoaded: false,
      classifcationModel: {
        name: '',
      },
    });
  },

  computed: {
    menuOpen() {
      return this.$store.state.menuOpen;
    },
  },

  watch: {
    menuOpen() {
      setTimeout(() => {
        this.getHeaderWidths();
        this.getStatsNameWidth();
      }, this.$options.constants.MENU_TIMEOUT);
    }
  },

  mounted() {
    Promise.all([
      this.getClassificationModel(),
      this.getStats(),
      this.getConfusion(),
    ]);
  },

  methods: {
    getDisplayNumberMaxWidth(category) {
      let maxWidth = 100;
      if (category.width) {
        maxWidth = Math.min(Math.max(category.minWidth, 100), category.width)
      }
      return `${maxWidth}px`
    },

    getWidth(ref, loc) {
      const padding = 24;
      const colArray = this.$refs[ref];
      if (colArray && colArray.length > 0) {
        const renderedColumn = colArray[0].$el;
        if (renderedColumn) {
          const width = renderedColumn.offsetWidth;
          if (width > padding) {
            loc.width = width - padding;
          }
        }
      }
    },

    getStatsNameWidth() {
      if (this.stats.length > 0) {
        this.stats.forEach(item => {
          this.getWidth(`stat${item.id}`, item);
        });
      }
    },

    getHeaderWidths() {
      if (this.confusion.length > 0) {
        const firstRow = this.confusion[0];
        firstRow.scores.forEach((col, i) => {
          this.getWidth(`${col.name}0`, this.confusion[i]);
        });
      }
    },

    getMinWidths(confusion) {
      confusion.forEach(row => {
        const widths = [];
        row.scores.forEach(col => {
          const dummyElement = document.createElement('div');
          dummyElement.style.display = 'inline-block';
          dummyElement.style.visibility = 'hidden';
          dummyElement.style.padding = '12px 20px';
          dummyElement.textContent = col.score;
          document.body.appendChild(dummyElement);
          const width = dummyElement.offsetWidth;
          document.body.removeChild(dummyElement);
          widths.push(width);
        });
        row.minWidth = Math.max(...widths);
      });
    },

    async getStats() {
      try {
        this.stats = await ClassifyModelAPI.getStats({
          modelId: this.classifcationModelId});
        setTimeout(() => {
          this.getStatsNameWidth();
        }, this.$options.constants.RENDER_TIMEOUT);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.statsLoaded = true;
      }
    },

    async getConfusion() {
      try {
        const response = await ClassifyModelAPI.getConfusion({
          modelId: this.classifcationModelId});
        const { categories, misclassified_files: misclassifiedFiles } = response;
        this.getMinWidths(categories);
        this.confusion = categories;
        setTimeout(() => {
          this.getHeaderWidths();
        }, this.$options.constants.RENDER_TIMEOUT);
        this.misclassifiedFiles = misclassifiedFiles || {};
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.confusionLoaded = true;
      }
    },

    getMisclassifiedFilesPerCell(rowName, colName){
      const row = this.misclassifiedFiles[rowName];
      return row && row[colName] || [];
    },

    getConfusionColClass(col, row) {
      return {
        'confusion-col__number--green': col.name === row.name,
        'confusion-col__number--red': col.score >= 1,
      };
    },

    async getClassificationModel() {
      this.classifcationModel = await ClassifyModelAPI.getModel({modelId: this.classifcationModelId});
    },

    async onMisclassifiedFileClick(file) {
      if (!this.isFileLinkable(file)) {
        return;
      }

      let routeData;
      if (file.filetype === 'email') {
        routeData = this.$router.resolve({ name: 'EmailView', params: { datasetId: file.dataset_id, emailId: file.id } });
      } else {
        const dataset = (await DatasetAPI.get(0, 1, file["dataset_name"])).data[0];
        routeData = this.$router.resolve({ name: 'Dataset', params: { id: dataset.id }, query: { entryName: file.filename } });
      }
      window.open(routeData.href, '_blank');
    },

    isFileLinkable(file) {
      return file !== null && typeof file === 'object';
    },

  },

  props: {
    classifcationModelId: {
      type: Number,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.classifier-info {
  .no-wrap-row {
    flex-wrap: nowrap;
    min-width: fit-content;
  }

  .first_col {
    min-width: 170px;
  }

  .category-name {
    width: fit-content;
    max-width: 100%;
  }

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }

  .confusion-col {
    padding: 0px;
    min-width: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &__number {
      &--red,
      &--green {
        border-radius: 8px;
      }

      &--red {
        background-color: #f78b98;
      }

      &--green {
        background-color: #cfe5b5;
      }
    }
  }

  .statitics-table-header__cell {
    display: flex;
    flex-direction: row-reverse;
  }

  .statistics-table__cell {
    display: flex;
    flex-direction: row-reverse;
    background-color: #DDEDFE;
  }

  .model {
    &__blue {
      position: relative;
      z-index: 2;
      text-align: right;
      background-color: #DDEDFE;
      height: 90%;

      &::before {
        content: "";
        position: absolute;
        top: -16px;
        right: -16px;
        bottom: -16px;
        left: -16px;
        background-color: #DDEDFE;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 16px);
        left: -16px;
        right: -16px;
        background-color: var(--v-tip-darken1);
        height: 1px;
      }
    }
  }
}

.misclassified-file-item {
  color: rgb(var(--v-theme-primary));
}
</style>
