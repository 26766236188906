<template>
  <div class="dataset-options">
    <div
      class="radio-box right-gap-sm bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.force_ocr.message`) }}
        </v-tooltip>
        <small>
          {{ $t("docTypes.force_ocr.name") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.force_ocr"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      class="right-gap-sm radio-box bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm left-gap-sm clickable"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.straighten_pages.message`) }}
        </v-tooltip>
        <small>
          {{ $t("docTypes.straighten_pages.name") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.straighten_pages"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        :disabled="!dataset.force_ocr || (org_config.ocr_model !== 'GOOGLE' && org_config.ocr_model !== 'AZURE')"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      class="right-gap-sm radio-box bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.use_deskew.message`) }}
        </v-tooltip>
        <small>
          {{ $t("docTypes.use_deskew.name") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.use_deskew"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        :disabled="!dataset.force_ocr"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      class="radio-box right-gap-sm bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.use_tables.message`) }}
        </v-tooltip>
        <small>
          {{ $t("docTypes.use_tables.name") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.use_tables"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      class="radio-box right-gap-sm bottom-gap-sm"
      style="width: fit-content"
      :class="{ 'inline-top': type === 'create' }"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <v-tooltip right>
          <template #activator="{ props }">
            <v-icon
              class="right-gap-sm clickable left-gap-sm"
              color="primary"
              size="16"
              v-bind="props"
            >
              fas fa-info-circle
            </v-icon>
          </template>
          {{ $t(`docTypes.detect_checkboxes.message`) }}
        </v-tooltip>
        <small>
          {{ $t("docTypes.detect_checkboxes.name") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.detect_checkboxes"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <div
      v-if="type === 'create'"
      class="right-gap-sm radio-box inline-top bottom-gap-sm"
      style="width: fit-content"
    >
      <div
        class="inline-middle"
        style="margin-top: -34px"
      >
        <small class="left-gap-sm">
          {{ $t("datasets.advanced") }}
        </small>
      </div>
      <v-switch
        v-model="dataset.advanced"
        class="inline-middle left-gap-sm"
        style="margin-top: -8px"
        color="primary"
        @change="$emit('change', dataset)"
        inset
      />
    </div>
    <v-select
      v-model="dataset.custom_reading_order"
      style="width: 370px !important; margin: 0px;"
      class="right-gap-sm inline-middle"
      variant="outlined"
      color="primary"
      density="compact"
      item-title="name"
      item-value="value"
      :class="{ 'inline-top': type === 'create' }"
      :items="readingOrderOpts"
      :disabled="dataset.is_legacy_ro"
      @update:model-value="$emit('change', dataset); updateOrderOptions();"
      hide-selected
    />
  </div>
</template>

<script>
import ui_mixin from '@/mixins/ui.js';
import { ConfigAPI } from '@/API/extract/ConfigAPI';

export default {
  name: 'DatasetOptions',

  mixins: [ui_mixin],

  data() {
    return ({
      org_config: {},
    })
  },

  watch: {
    dataset() {
      this.updateOrderOptions();
    }
  },

  async mounted() {
    this.org_config = await ConfigAPI.get();
    setTimeout(() => {
      this.updateOrderOptions();
    }, 100);
  },

  methods: {
    updateOrderOptions() {
      this.readingOrderOpts = [
        {
          name: this.$t('docTypes.custom_reading_order.default'),
          default_name: this.$t('docTypes.custom_reading_order.default'),
          value: null,
        },
        {
          name: this.$t('docTypes.custom_reading_order.roma2'),
          default_name: this.$t('docTypes.custom_reading_order.roma2'),
          value: 'roma2',
        },
        {
          name: this.$t('docTypes.custom_reading_order.roma3'),
          default_name: this.$t('docTypes.custom_reading_order.roma3'),
          value: 'roma3',
        },
      ];
      const readingOrderOption = this.readingOrderOpts.find(o => o.value === this.dataset.custom_reading_order);
      if (readingOrderOption) {
        readingOrderOption.name = `${this.$t('docTypes.custom_reading_order.name')} ${readingOrderOption.default_name}`
        this.readingOrderOpts = [...this.readingOrderOpts];
      }
    }
  },

  props: {
    dataset: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
    }
  },

  emits: ['change'],
}
</script>
