<template>
  <v-dialog
    v-model="openDialog"
    max-width="450"
    @click:outside="openDialog = false"
    @keydown.esc="openDialog = false"
    @keydown.enter="handleEnter"
  >
    <v-card class="dialog-card">
      <h2 class="dialog-title mb-8">
        {{ title }}
      </h2>
      {{ message }}
      <slot name="extra-content" />
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="openDialog = false"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="$emit('confirm')"
            block
            rounded
          >
            {{ $t('confirm') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',

  data() {
    return ({
      openDialog: this.modelValue,
    });
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },
  },

  methods: {
    handleEnter() {
      if (this.openDialog) {
        this.$emit('confirm');
      }
    },
  },

  props: {
    title: {
      required: true,
      type: String,
    },

    message: {
      required: true,
      type: String
    },

    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['confirm', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}
</style>
