<template>
  <v-chip
    class="max-width-chip bottom-gap-sm right-gap-sm"
    variant="outlined"
    :class="{ 'small-chip': small }"
    :style="chipStyle"
    @mouseenter="$emit('hover')"
  >
    <div
      ref="chipValue"
      class="ellipsis inline-middle"
      :style="{ maxWidth: valueWidth }"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <span
        v-for="(text, textIndex) in textArray"
        :key="textIndex"
      >
        {{ text }}
      </span>
    </div>
    <v-icon
      v-if="closeable"
      class="inline-middle close-icon"
      color="black"
      size="14"
      @click="$emit('closeClick')"
    >
      fas fa-times
    </v-icon>
    <v-tooltip
      v-if="hovered && isTooLong()"
      location="right"
      :activator="$refs.chipValue"
    >
      <template #default>
        <small>
          <span
            v-for="(text, textIndex) in textArray"
            :key="textIndex"
          >
            {{ text }}
          </span>
        </small>
      </template>
    </v-tooltip>
  </v-chip>
</template>

<script>
export default {
  name: 'MaxWidthChip',

  data() {
    return ({
      hovered: false,
    });
  },

  computed: {
    valueWidth() {
      return this.showFull ? '' : `${this.maxWidth - 40}px`;
    },

    chipMaxWidth() {
      return this.showFull ? '' : `${this.maxWidth}px !important`;
    },

    chipStyle() {
      return {
        'max-width': this.chipMaxWidth,
        'background-color': `${this.color}60 !important`,
        'border-color': `${this.color} !important`,
      };
    },
  },

  methods: {
    isTooLong() {
      return this.$refs.chipValue.scrollWidth > this.$refs.chipValue.clientWidth;
    },
  },

  props: {
    color: {
      type: String,
      default: '#502BFF',
    },

    textArray: {
      type: Array,
      required: true,
    },

    closeable: {
      type: Boolean,
      default: false,
    },

    showFull: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
      default: 180,
    },
  },

  emits: ['hover', 'closeClick'],
}
</script>

<style lang="scss" scoped>
.max-width-chip {
  text-transform: none !important;
  color: black;
  width: min-content;

  .close-icon {
    margin-left: 5px;
  }
}

.small-chip {
  font-size: 0.7rem !important;
  height: 25px !important;
}
</style>
