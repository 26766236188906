import { http } from '@/plugins/axios';

const BASE_PATH = 'workflows/api/v1/resources/';

export class WorkflowResourcesAPI {
  static getResourceFolders({ offset, limit, parent_id = 0, name_filter = null }) {
    const params = {
      offset,
      limit,
    };
    if (name_filter) {
      // when we filter by name, we ignore the parent to search in all folders
      params.name_filter = name_filter;
    } else if (parent_id) {
      params.parent_id = parent_id;
    } else {
      params.in_root = true;
    }
    return http.get(`${BASE_PATH}folders/`, {
      params
    })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getResourceFiles({ offset, limit, folder_id = 0, name_filter = null }) {
    const params = {
      offset,
      limit,
    };
    if (name_filter) {
      // when we filter by name, we ignore the folder to search in all files
      params.name_filter = name_filter;
    } else if (folder_id) {
      params.folder_id = folder_id;
    } else {
      params.in_root = true;
    }
    return http.get(`${BASE_PATH}files/`, {
      params
    })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static createFolder({ name, parent_id = 0 }) {
    const data = { name };
    if (parent_id) {
      data.parent_id = parent_id;
    }
    return http.post(`${BASE_PATH}folders/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getFile(id) {
    return http.get(`${BASE_PATH}files/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getFolder(id) {
    return http.get(`${BASE_PATH}folders/${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static uploadFile(file, folder_id = null) {
    const formData = new FormData();
    formData.append("file_in", file);
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    const url = folder_id ? `${BASE_PATH}files/?folder_id=${folder_id}` : `${BASE_PATH}files/`;
    return http.post(url, formData, headerConfig)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static deleteFiles(ids) {
    return http.delete(`${BASE_PATH}files/bulk/`, { data: { ids } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static deleteFolders(ids) {
    return http.delete(`${BASE_PATH}folders/bulk/`, { data: { ids } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static renameFolder(id, name) {
    return http.post(`${BASE_PATH}folders/${id}/rename/`, { name })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static renameFile(id, name) {
    return http.post(`${BASE_PATH}files/${id}/rename/`, { name })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static moveFolder(id, target) {
    const params = { target_folder_id: target || null };
    return http.post(`${BASE_PATH}folders/${id}/move/`, {}, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static moveFile(id, target) {
    const params = { target_folder_id: target || null };
    return http.post(`${BASE_PATH}files/${id}/move/`, {}, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static getSupportedExtensions() {
    return http.get(`${BASE_PATH}files/extensions/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
