import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/classification-agents';

export class ClassificationAgentsAPI {
  static fetchAll({
    name = null,
    classificationModelType = null,
    offset = 0,
    limit = 20,
    sortDesc = true,
    hasHITLDocuments = null,
  }) {
    return http
      .get(`${BASE_PATH}`, {
        params: {
          name,
          classification_model_type: classificationModelType,
          offset,
          limit,
          sort_desc: sortDesc,
          has_hitl_documents: hasHITLDocuments,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static createClassificationAgent({
    name,
    threshold,
    isRelativeThreshold,
    additionalCategories = [],
    classificationModelId,
    categoriesMap = {},
    rejectReasons = [],
    ocrModel = 'google',
  }) {
    return http
      .post(`${BASE_PATH}`, {
        name,
        threshold,
        is_relative_threshold: isRelativeThreshold,
        additional_categories: additionalCategories,
        model_id: classificationModelId,
        categories_map: categoriesMap,
        reject_reasons: rejectReasons,
        ocr_model: ocrModel,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static updateClassificationAgent({
    id,
    name,
    description,
    threshold,
    isRelativeThreshold,
    additionalCategories,
    classificationModelId,
    categoriesMap,
    rejectReasons,
    ocrModel,
  }) {
    return http
      .put(`${BASE_PATH}/${id}`, {
        name,
        description,
        threshold,
        is_relative_threshold: isRelativeThreshold,
        additional_categories: additionalCategories,
        model_id: classificationModelId,
        categories_map: categoriesMap,
        reject_reasons: rejectReasons,
        ocr_model: ocrModel
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static fetchClassificationAgent({ id }) {
    return http
      .get(`${BASE_PATH}/${id}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static deleteClassificationAgents({ ids }) {
    return http
      .delete(`${BASE_PATH}`, {
        data: { ids },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static fetchDocuments({
    classificationAgentId,
    filename = null,
    hitlRequired = null,
    offset = 0,
    limit = 20,
    sortDesc = true,
  }) {
    return http
      .get(`${BASE_PATH}/${classificationAgentId}/documents`, {
        params: {
          filename,
          hitl_required: hitlRequired,
          offset,
          limit,
          sort_desc: sortDesc,
        },
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
