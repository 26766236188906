<template>
  <div
    class="corrections-view page-padding py-7"
  >
    <BreadcrumbComponent />
    <PageTabs
      v-model="activeTab"
      :tabs="tabItems"
    />
    <ExtractionAgentsTable
      v-if="activeTab === 'extractionAgentsReview'"
    />
    <ClassificationAgentsTable
      v-else-if="activeTab === 'classificationAgentsDocumentsReview'"
    />
    <DocumentsTable
      v-else-if="activeTab === 'classificationReview'"
      :total-documents="totalDocuments"
    />
    <EmailsReviewTable
      v-else-if="activeTab === 'emailsReview'"
    />
  </div>
</template>

<script>
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';
import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import PageTabs from '@/components/common/elements/General/PageTabs';
import ExtractionAgentsTable from "@/components/extract/views/Corrections/DocumentsExtraction/ExtractionAgentsTable.vue";
import { VerifyAPI } from '@/API/extract/VerifyAPI';
import EmailsReviewTable from '@/components/extract/views/Corrections/EmailsClassification/EmailsReviewTable.vue';
import { EmailsAPI } from '@/API/classify/EmailsAPI';
import ClassificationAgentsTable from '@/components/extract/views/Corrections/DocumentsClassification/ClassificationAgentsTable.vue';
import { ClassificationAgentsAPI } from '@/API/classify/ClassificationAgentsAPI';
import DocumentsTable from '@/components/extract/views/Corrections/DocumentsClassification/DocumentsTable.vue';

export default {
  name: 'CorrectionsView',

  components: {
    BreadcrumbComponent,
    ClassificationAgentsTable,
    PageTabs,
    ExtractionAgentsTable,
    EmailsReviewTable,
    DocumentsTable,
  },

  data() {

    return {
      totalDocuments: 0,
      totalDocumentClassificationAgents: 0,
      totalExtractionAgents: 0,
      totalEmailsToReview: 0,
      classifyRunning: this.$store.getters.serviceStatus.classify.running,
      activeTab: '',
    };
  },

  computed: {
    tabItems() {
      const tabs = [
        {
          title: this.$t('workflows.steps.extraction'),
          bigNumber: this.totalExtractionAgents,
          name: 'extractionAgentsReview',
        },
      ];
      if (this.classifyRunning) {
        tabs.push({
          title: this.$t('classificationAgents.classification_agents'),
          bigNumber: this.totalDocumentClassificationAgents,
          name: 'classificationAgentsDocumentsReview',
        });
        tabs.push({
          title: this.$t('workflows.steps.classification'),
          bigNumber: this.totalDocuments,
          name: 'classificationReview',
        });
        tabs.push({
          title: 'Emails',
          bigNumber: this.totalEmailsToReview,
          name: 'emailsReview',
        });
      }
      return tabs;
    },
  },

  watch: {
    activeTab(tab) {
      this.$router.replace({ query: { activeTab: tab } });
    }
  },

  mounted() {
    this.activeTab = this.initialTab;
    if (!this.classifyRunning) {
      this.activeTab = 'extractionAgentsReview';
      this.$router.replace({ query: { activeTab: 'extractionAgentsReview' } });
    }

    this.$store.commit('setBreadcrumb', [
      { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
      { title: this.$t('menu.production') },
      { title: this.$t('menu.corrections.title') },
    ]);

    this.fetchTotalExtractionAgents();
    if (this.classifyRunning) {
      Promise.all([
        this.fetchTotalDocuments(),
        this.fetchTotalEmailsToReview(),
        this.fetchTotalDocumentClassificationAgents(),
      ]);
    }
  },

  methods: {
    async fetchTotalExtractionAgents() {
      const response = await VerifyAPI.getExtractionAgentsToReview({
        limit: 1,
        offset: 0,
      });
      if (response) {
        this.totalExtractionAgents = parseInt(response.headers['x-total-count']);
      }
    },

    async fetchTotalDocuments() {
      const response = await ClassifyDocumentsAPI.get(0, 1, true, '', false);
      if (response) {
        this.totalDocuments = parseInt(response.headers['x-total-count'], 10);
      }
    },

    async fetchTotalDocumentClassificationAgents() {
      const response = await ClassificationAgentsAPI.fetchAll({
        classificationModelType: 'document_models',
        hasHITLDocuments: true,
        limit: 1,
        offset: 0,
      });
      if (response) {
        this.totalDocumentClassificationAgents = parseInt(response.headers['x-total-count'], 10);
      }
    },

    async fetchTotalEmailsToReview() {
      const response = await EmailsAPI.fetchEmails({
        limit: 1,
        offset: 0,
        requireHumanReview: true,
      });
      if (response) {
        this.totalEmailsToReview = parseInt(response.headers['x-total-count'], 10);
      }
    }
  },

  props: {
    initialTab: {
      type: String,
      default: 'extractionAgentsReview'
    },
  }
}
</script>

<style lang="scss" scoped>

.corrections-view {
  height: fit-content;
}

</style>
